




import React, {  useEffect, useState } from "react";
// import React, {Component, useContext, useEffect, useState } from "react"; unused component
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import stars from '../images/stars.png'

import { isMobile } from 'react-device-detect';
import { Rate } from 'antd'
const mobimg={width: "100%", height: "300px" ,marginTop:'20px'}
const deskimg={width: "80%", height: "350px" ,marginTop:'20px'}


var settings = {
  

  dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 2,

  //   speed: 5000,
  //   autoplaySpeed: 1000,
  // cssEase: "linear",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 4,
        initialSlide: 2,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 1,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
};
function RewiewsMob() {
  
  const arr = [
    {
      name : "Tarang Madduri",
      image : "https://lh3.googleusercontent.com/a/AAcHTtdrrtPh4io4lJp1OVZqXY_utESHInd9V6udy_BXXNEC=s128-c0x00000000-cc-rp-mo",
       ratings : <Rate allowHalf defaultValue={5} />,
       text : "I recently had the opportunity to work with this software company, and I must say, I'm thoroughly impressed. Their team was incredibly knowledgeable and professional throughout the entire process. They delivered a high-quality product that exceeded my expectations. The software they developed was intuitive, user-friendly, and met all our specific requirements. Their customer support was also top-notch, promptly addressing any questions or concerns. I highly recommend this company for their exceptional software solutions.",
      
    },
    {
        name : "Beast OPk",
        image : "https://lh3.googleusercontent.com/a/AAcHTtdIiYUKLXLgZVYDBzaBagOei3BUryP1VaqM8dp1vRVh=s128-c0x00000000-cc-rp-mo",
       ratings : <Rate allowHalf defaultValue={5} />,
       text : "Good company and quality products delivery within time. Nice place to work and work pressure is less when compare to other companies. Almost I can say it is a work life balance focused.. They're Doing good for society too..",
    
    },
    {
       name : "Kushi G A",
       
      image : "https://lh3.googleusercontent.com/a-/AD_cMMRuEn813M2QDeGBKjKbH8dacg9lv5QzIXZQxjDlzrinEU0=s128-c0x00000000-cc-rp-mo",
       ratings : <Rate allowHalf defaultValue={5} />,
   
       text : "They are very sharp and have a high-quality team. I expect quality from people, and they have the kind of team I can work with. Anisys Technology is very good in terms of quality they deliver to the clients.They were upfront about everything that needed to be done.Very happy with the service .",
      
    },
    {
     name : "Tejaswini TS",
     
     image : "https://lh3.googleusercontent.com/a/AAcHTtcpmq-HUlbiA1_BRoA2iaFTjUzdN2t8jIX5dEEEofjn=s128-c0x00000000-cc-rp-mo",
       ratings : <Rate allowHalf defaultValue={5} />,
       text : "They provide quality of service based on customer needs and requirements. Their professionalism, expertise, and commitment to delivering exceptional results make them a standout choice.",
    
    },
    {
       name : "Akshay",
      
      image: "https://lh3.googleusercontent.com/a-/AD_cMMSCEdklRPCjzKcce-dWRw6XV9n11uPn2LTGb3M46TCJHHns=s128-c0x00000000-cc-rp-mo-ba2",
       ratings : <Rate allowHalf defaultValue={5} />,
     
       text : "A place where creativity meets practicality...\nAnisys Technology is very good in terms of quality they deliver to the clients.\nI loved their work.\nThanks to my friend who suggested this company which built my dream application.",

    }
 ]
 


  return (
    <>
   
   
    
    
            
                <Slider {...settings}>
                 
                {arr.map((val) => {
              return (
                <div className="m-1">
                <div
  className="testimonials__item swiper-slide swiper-slide-active "
  data-swiper-slide-index={0}
  role="group"
  aria-label="1 / 3"
  style={{ width: 410, marginRight: 30 }}
>
  <div className="testimonials__item-content">
    <div className="testimonials__logo">
      <img
        decoding="async"
        src="/static/media/star.f66c31259c9648992224.png"
        alt="image"
      />
    </div>
    <div className="testimonials__text">
      <p>
      {val.text.slice(0,100)}...
      </p>
    </div>
    <div className="testimonials__client d-flex align-items-center">
      <div className="testimonials__client-thumb">
      <img src={val.image}  style={{width:'80px'}}/>

        
      </div>
      <div className="testimonials__client-text">
        <h4>{val.name}</h4>
      </div>
    </div>
  </div>
</div>
</div>

            
              );
            })}
                </Slider>
            
 
   
             

    </>
  );
}

export default RewiewsMob;

