




import React, {  useEffect, useState } from "react";
// import React, {Component, useContext, useEffect, useState } from "react"; unused component
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const mobimg={width: "100%", height: "300px" ,marginTop:'20px'}
const deskimg={width: "80%", height: "350px" ,marginTop:'20px'}


var settings = {
  

  dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 2,

  //   speed: 5000,
  //   autoplaySpeed: 1000,
  // cssEase: "linear",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 4,
        initialSlide: 2,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
};
function Companies() {
  
  const arr = [
    {
     
      image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-3.png",
       
      
    },
    {
     
        image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-4.png",
         
        
      },
      {
     
        image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-5.png",
         
        
      },
      {
     
        image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-2.png",
         
        
      },
      {
       
          image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-3.png",
           
          
        },
        {
       
          image : "https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/logo-3.png",
           
          
        },
   
 ]
 


  return (
    <>
   
   
    
   <div className="customers__container">
                    <div className="swiper brand__slider swiper-initialized swiper-horizontal swiper-pointer-events">
            
                <Slider {...settings}>
                   

                {arr.map((val) => {
              return (
             <div style={{border:'1px solid white',borderRadius:'10px'}}>
                <img src={val.image} className="" style={{width:'100px'}}/>
             </div>


            
              );
            })}
          
                </Slider>
                </div>


</div>
            
 
   
             

    </>
  );
}

export default Companies;

