import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
// import { Link } from "react-router-dom";
import ani from '../images/ani.jpg'


const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="row d-lg-none mb-3" >
    <div className='row d-flex mb-5 ' >
    <div><a href="/"><img src={ani} style={{width:'150px',height:'50px',position:'absolute',left:'20px',top:'10px'}}/></a></div>
    <div>
      {/* Menu Icon */}
      <div onClick={handleToggleSidebar} style={{position:'absolute',right:'10px',top:'20px',color:'black !important',zIndex:'1',width:'50px'}} >
        {isOpen ? (
          <AiOutlineClose className="menu-icon" style={{width:'50px',height:'25px'}}/>
        ) : (
          <AiOutlineMenu className="menu-icon" style={{width:'50px',height:'25px'}}/>
        )}
      </div>

      {/* Sidebar Content */}
      {isOpen && (
        <div className="sidebar" style={{marginBottom:'-45px',backgroundColor:'rgb(222 226 230 / 2%)'}}>
          {/* Sidebar content goes here */}
          <div className=" p-3 w-100">
            <center className="mt-5">
              <a
                className="navLink mt-1"
                href="/"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  HOME
                </b>
              </a>
            </center>
            <center className="mt-2">
              <a
                className="navLink mt-1"
                href="/about"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  ABOUT US
                </b>
              </a>
            </center>
            <center className="mt-2">
              <a
                className="navLink mt-1"
                href="/services"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  SERVICES
                </b>
              </a>
            </center>
            <center className="mt-2">
              <a
                className="navLink mt-1"
                href="/pricing"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  PRICING
                </b>
              </a>
            </center>
            <center className="mt-2">
              <a
                className="navLink mt-1"
                href="/contact"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  CONTACT US
                </b>
              </a>
            </center>
            <center className="mt-2">
              <a
                className="navLink mt-1"
                href="/career"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  CAREERS
                </b>
              </a>
            </center>
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
  );
};

export default MobileSidebar;



