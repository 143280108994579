

import React from 'react';
import calling from '../images/calling.png';

const PhoneCall = ({ phoneNumber }) => {
  const handleCall = () => {
    window.location.href = 'tel:+918123721201'; // Replace with the desired phone number
  };

  const containerStyle = {
    position: 'fixed',
    bottom: '70px',
    left: '13px',
    width: '130px',
    height: '130px',
    borderRadius: '65px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

 

  return (
      <img src={calling} onClick={handleCall} style={containerStyle}/>
 
  );
};

export default PhoneCall;

