import React,{useState,useEffect} from 'react'

const NumberList2 = ()=>{
    const[count,setCount]=useState(1)
    useEffect(()=>{
        setTimeout(()=>{
if(count < 100){
    setCount(count+1)
}
        },65)
    })
    return(
        <>
     <p  style={{fontSize:'42px',color:'white',fontWeight:'bold'}}>  +{count}</p>
        </>
    )
}

export default NumberList2