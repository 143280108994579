import React, { useState } from "react";
import { Button, Form, Input, InputNumber, Slider } from "antd";
import { isMobile } from "react-device-detect";

const mobInput = {
  width: "100%",
  padding: "10px",
};
const deskInput = {
  // width:'23vw',
  // padding:'7px'
};
const mobInput1 = {
  width: "100%",
  padding: "7px",
};
const deskInput1 = {
  width: "48vw",
  padding: "7px",
};
const mobWidth = {
  width: "100%",
  border: "1px solid white",
  padding: "10px",
  boxShadow: "0px 0px 2px 2px rgb(13 110 253 / 21%)",
};
const deskWidth = {
  width: "100%",
  border: "1px solid #7432ff",

  boxShadow: "0px 0px 2px 2px rgb(13 110 253 / 21%)",
  padding: "10px",
  borderRadius: "10px",
};
const mobCap = {
  marginTop: "20px",
};
const deskCap = {};
const mobbtn = { backgroundColor: "#7432ff", border: "1px solid #7432ff" };
const deskbtn = {
  float: "right",
  backgroundColor: "#7432ff",
  border: "1px solid #7432ff",
};
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {
  console.log(values);
};
const RequirementsForm = () => {
  const [activeButton, setActiveButton] = useState(null);
  const[name,setName]=useState("")
  const[phone,setPhone]=useState("")
  const [sliderValue, setSliderValue] = useState(""); 
  // const [sliderValue, setSliderValue] = useState(10000); 
  const[amountValue,setAmountValue]=useState("");
  const[message,setMessage]=useState("")
  const [isSliderActive, setIsSliderActive] = useState(false); // State to track the slider's activity
  const [isAmountActive, setIsAmountActive] = useState(true); // State to track the amount field's activity
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [nameError, setNameError] = useState(""); // State to track the name input field error
  const [phoneError, setPhoneError] = useState(""); // State to track the phone number input field error
  const [errorMessage, setErrorMessage] = useState("");


  const buttonData = [
    { label: "Mobile App Development", value: 15000 },
    { label: "Website Development", value: 15000 },
    { label: "E-commerce Development", value: 15000 },
  ];

  const handleButtonClick = (buttonNumber) => {
    if (isAmountActive) {
      setActiveButton(buttonNumber);
      setSliderValue(buttonData[buttonNumber].value);
      setIsSliderActive(true);
    }
  };

  const handleSliderChange = (event) => {
    setSliderValue(Number(event.target.value));
  };

  const handleAmountChange = (event) => {
    setAmountValue(event.target.value);

    setIsAmountActive(false); // Set isAmountActive to false when the amount field is selected
    setIsSliderActive(false);
    setActiveButton(null); // Disable the active button when the amount field is selected
  };
  const handleNameChange = (event) => {
    const input = event.target.value;
    setName(input);

    const regex = /^[a-zA-Z ]+$/; // Regular expression to allow only letters and spaces
    if (!regex.test(input)) {
      setNameError("Only alphabets are allowed"); // Set the error message when the input doesn't match the regex
    } else {
      setNameError(""); // Reset the error message when the input matches the regex
    }
  };
  const handlePhoneChange = (event) => {
    const input = event.target.value;
    setPhone(input);

    const regex = /^[0-9]{0,10}$/; // Regular expression to allow only numbers and limit to 10 digits
    if (!regex.test(input)) {
      setPhoneError("Only numbers are allowed, and the maximum length is 10 digits"); // Set the error message when the input doesn't match the regex
    } else {
      setPhoneError(""); // Reset the error message when the input matches the regex
    }
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log(file); // Do something with the selected file
  // };
  

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleSelectChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };
  const handleSelectChange2 = (event) => {
    setSelectedValue2(event.target.value);
  };

  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);

  const generateCaptcha = () => {
    const number1 = Math.floor(Math.random() * 10);
    const number2 = Math.floor(Math.random() * 10);
    setNum1(number1);
    setNum2(number2);
    setUserInput("");
    setIsValid(false);
    setIsError(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    setIsValid(parseInt(e.target.value) === num1 + num2);
    setIsError(false);
  };

  const handleFormSubmit = () => {
    if (!name || !phone || !selectedFile || !message) {
      setErrorMessage("Please fill in all the required fields.");
      return; // Stop further execution
    }
    var formdata = new FormData();
    formdata.append("Name", name);
    formdata.append("PhoneNumber", phone);
    formdata.append("SliderValue", sliderValue);
    formdata.append("Amount", amountValue); // Assuming the slider value is the amount
    formdata.append("image", selectedFile); // Assuming selectedFile is the uploaded image
    formdata.append("Message", message);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://formapi-zvb5.onrender.com/requirement-form", requestOptions)
      .then(response => response.text())
      .then(result =>{ console.log(result)
        setErrorMessage("");
        setName('')
    setPhone('')
    setSliderValue('')
    setAmountValue('')
    setMessage('')
    setIsSubmitted(true); // Set the submission status to true after successful form submission
  

  })
      .catch(error => console.log('error', error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      // CAPTCHA is valid, handle form submission or other actions
      handleFormSubmit(); // Call the API integration function
      console.log("CAPTCHA is valid!");
    } else {
      // CAPTCHA is not valid, display error message
      setIsError(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file); // Set the selected file in state
  };



  // const handleFileChange = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  return (
    <>
      <center>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          // style={isMobile?mobWidth:deskWidth}
          validateMessages={validateMessages}
        >
          <div className="row mt-3">
            <div className="col-lg-6 mt-2">
              <input
                type="text"
                id="name"
                 value={name}
                 onChange={handleNameChange}
                placeholder="Enter your name"
              />
               {nameError && (
          <small style={{ color: "red", marginTop: "5px" }}>{nameError}</small>
        )}
            </div>
            <div className="col-lg-6 mt-2">
              <input
                type="text"
                id="phoneNumber"
                value={phone}
                 onChange={handlePhoneChange}
                placeholder="Enter your phone number"
              />
              {phoneError && (
          <small style={{ color: "red", marginTop: "5px" }}>{phoneError}</small>
        )}
            </div>
          </div>

          <div className="row mt-4">
            {buttonData.map((button, index) => (
              <div className="col-lg-4 mt-2 ">
                <button
                  key={index}
                  onClick={() => handleButtonClick(index)}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    color: "white",
                    width: "100%",
                    fontWeight: "bold",
                    backgroundColor: isAmountActive
                      ? "#7432ff"
                      : "rgb(116 50 255 / 50%)", // Adjusting button color when amount is active
                    border: isAmountActive
                      ? "1px solid #7432ff"
                      : "rgb(116 50 255 / 50%)",
                    pointerEvents: isAmountActive ? "auto" : "none", // Disabling pointer events if amount is active
                  }}
                  disabled={!isAmountActive} // Disabling buttons if amount is active
                >
                  {button.label}
                </button>
              </div>
            ))}

            {activeButton !== null && (
              <div className="mt-2">
                <input
                  type="range"
                  min={buttonData[activeButton].value}
                  max={1000000}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  disabled={!isSliderActive} // Disabling slider if it's not active
                />
                <span>
                  Amount {sliderValue} (Min: {buttonData[activeButton].value},
                  Max: 1000000)
                </span>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between mt-5">
            <hr style={{ width: "45%" }} />
            <p style={{ fontSize: "20px" }}>OR</p>
            <hr style={{ width: "45%" }} />
          </div>
          <div className="col-lg-12">
            <label style={{ float: "left" }}> Amount</label>
            <br />
            <input
              type="number"
              id="amount"
              placeholder="Enter the amount"
              value={amountValue}
              onChange={handleAmountChange}
              style={{ pointerEvents: isSliderActive ? "none" : "auto" }} // Disabling pointer events if slider is active
            />
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <label htmlFor="fileInput" style={{ float: "left" }}>
                Choose File
              </label>
              <input type="file" id="file" onChange={handleFileChange} />
            </div>

            <div className="col-lg-6  ">
              {/* <form onSubmit={handleSubmit}> */}
              <div style={isMobile ? mobCap : deskCap}>
                <label>
                  {num1} + {num2} =
                </label>
                <input
                  type="text"
                  value={userInput}
                  onChange={handleInputChange}
                />

                {isError && (
                  <p className="error text-danger">
                    Invalid CAPTCHA, please try again.
                  </p>
                )}
              </div>
              {/* </form> */}
              <button
                onClick={generateCaptcha}
                style={{
                  border: "2px solid #7432ff",
                  height: "30px",
                  marginTop: "10px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  fontSize: "16px",
                  padding: "5px",
                  backgroundColor: "#7432ff",
                  border: "1px solid #7432ff",
                }}
              >
                <p style={{ marginTop: "-5px", color: "white" }}>
                  Generate CAPTCHA
                </p>
              </button>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12 ">
              <textarea
                style={{ width: "100%" }}
                id="message"
                value={message}
                 onChange={handleMessageChange}
                placeholder="Enter your message"
                rows="4" // Specify the number of rows you want to display
              />
            </div>
          </div>
        
          
            <button className="p-2 w-100 mt-3" onClick={handleSubmit} style={{border:'1px solid #7432ff',borderRadius:'10px',backgroundColor:' #7432ff',
          color:'white',fontSize:'18px'}}>Submit</button>

{isSubmitted && (
          <p style={{ color: "green", marginTop: "10px" }}>Form submitted successfully!</p>
        )}
         
         {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
      
        </Form>
      </center>
    </>
  );
};

export default RequirementsForm;

