import React from 'react'
import ContactForm from '../forms/ContactForm'
import { TfiAngleDoubleRight } from "react-icons/tfi";
import { BsArrowDown, BsFillEnvelopeAtFill, BsFillGeoAltFill, BsFillTelephoneFill } from 'react-icons/bs';
import Map from '../../common/Map';


const Contact = () => {
  return (
    <div>
        <>
  {/* preloader area start */}
  
  {/* preloader area end */}
  {/* back to top start */}
  <div className="progress-wrap">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
  {/* back to top end */}
  {/* header start */}
  {/* header start */}
 
  {/* header end */}
  {/* side info start */}
  {/* mobile menu sidebar start */}
  <div className="fix">
    <div className="side-info">
      <button className="side-info-close">
        <i className="fas fa-times" />
      </button>
      <div className="side-info-content">
        <div className="mobile-menu" />
        <div className="sidebar__contact mb-30">
          <div className="contact-list mb-30">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt" />
                66 Broklyn Street, New York United States of America{" "}
              </li>
              <li>
                <i className="far fa-paper-plane" />
                <a href="mailto:http://info@example.com">info@example.com</a>
              </li>
              <li>
                <i className="fas fa-phone" />
                <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
              </li>
            </ul>
            <div className="sidebar__social">
              <a href="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
              <a href="#">
                <i className="fab fa-google" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* side info end */}
  <div className="offcanvas-overlay" />
  {/* mobile menu sidebar end */}
  {/* header end */}
  {/* wrapper-box start */}
  {/* page-title area start */}
  <section className="page__title-area page__title-height fix d-flex align-items-center p-relative  ">
    <div className="page__title-shape">
      <img
        className="page-title-s-1 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-1.png"
        alt="image"
      />
      <img
        className="page-title-s-3"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-3.png"
        alt="image"
      />
      <img
        className="page-title-s-4"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-4.png"
        alt="image"
      />
      <img
        className="page-title-s-5 wow fadeInDown"
        data-wow-delay="1s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-5.png"
        alt="image"
      />
      <img
        className="page-title-s-6 wow fadeInUp"
        data-wow-delay=".6s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-6.png"
        alt="image"
      />
      <img
        className="page-title-s-7 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-7.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xxl-12">
          <div className="page__title-wrapper">
            <h2 className="page__title-heading">Contact </h2>
            <nav
              aria-label="Breadcrumbs"
              className="breadcrumb-trail breadcrumbs"
            >
              <ul
                className="trail-items"
                itemScope=""
                itemType="http://schema.org/BreadcrumbList"
              >
                <li
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                  className="trail-item trail-begin"
                >
                  <a
                    href="/"
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">Home</span>
                  </a>&nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                  <meta itemProp="position" content={1} />
                </li>
                
                <li className="trail-item trail-end">
                  <span>Contact</span>
                </li>
              </ul>
            </nav>{" "}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* page-title area end */}
  <div
    data-elementor-type="wp-page"
    data-elementor-id={82}
    className="elementor elementor-82"
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-784cbbb elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="784cbbb"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3dfd40c"
          data-id="3dfd40c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-ca02ded elementor-widget elementor-widget-contact-info"
              data-id="ca02ded"
              data-element_type="widget"
              data-widget_type="contact-info.default"
            >
              <div className="elementor-widget-container">
                <section className="contact__area fix p-relative pt-120 pb-190">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-xl-6 col-lg-7 col-md-8">
                        <div className="contact__info">
                          <div className="contact__info-content d-flex mb-30">
                            <div className="contact__info-icon white">
                              <BsFillGeoAltFill style={{color:'white'}}/>
                            </div>
                            <div className="contact__info-title">
                              <h5>Office Address</h5>
                              <p>
                              Old Baiyyappanahalli, Extension, Bengaluru, Karnataka 560033
                              </p>
                            </div>
                          </div>
                          <div className="contact__info-content d-flex mb-30">
                            <div className="contact__info-icon green">
                              <BsFillTelephoneFill style={{color:'white'}}/>
                            </div>
                            <div className="contact__info-title">
                              <h5>Phone</h5>
                              <p>
                                <a href="tel:+0066442211">+918123721201</a>
                              </p>
                             
                              <p />
                            </div>
                          </div>
                          <div className="contact__info-content d-flex mb-30">
                            <div className="contact__info-icon blue">
                              <BsFillEnvelopeAtFill style={{color:'white'}}/>
                            </div>
                            <div className="contact__info-title">
                              <h5>Email us</h5>
                              <p>
                                <a href="mailto:info@example.com">
                                corporate@anisystech.com
                                </a>
                              </p>
                              <p>
                                
                              </p>
                              <p />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 offset-xl-1 col-lg-5 col-md-4">
                        <div className="contact__title">
                          <h2 className="section__title">
                            <span className="first_title">
                              Ready to get our
                            </span>
                            <span className="second_title">
                              marketing services
                            </span>
                          </h2>
                          <div className="contact__title-icon">
                            <a href="#contact">
                              <BsArrowDown style={{fontSize:'80px'}}/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-0cf72f2 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="0cf72f2"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2b61a75"
          data-id="2b61a75"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-11c1434 elementor-widget elementor-widget-contact-form"
              data-id="11c1434"
              data-element_type="widget"
              data-widget_type="contact-form.default"
            >
              <div className="elementor-widget-container">
                <section
                  id="contact"
                  className="project__area project__area-2 p-relative pt-130 mb-120"
                >
                  <div className="project__shape contact__shape">
                    <img
                      decoding="async"
                      className="project-s-1 c-s-1"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-1.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="project-s-2 c-s-2"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-2.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="project-s-3 c-s-3"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-3.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="project-s-4 c-s-4 d-none d-lg-block"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-4.png"
                      alt="image"
                    />
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-9 col-lg-10">
                        <div className="project__thumb d-sm-flex">
                          <div className="project__thumb-1">
                            <img
                              decoding="async"
                              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/project-img-1.jpg"
                              alt="image"
                            />
                          </div>
                          <div className="project__thumb-2 project__thumb-3 d-none d-sm-block">
                            <img
                              decoding="async"
                              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/project-img-2.jpg"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                     <ContactForm/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Map/>
  </div>
 


</>

    </div>
  )
}

export default Contact