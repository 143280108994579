import React from 'react'
import star from '../images/star.png'
import ReviewsMob from '../review/ReviewsMob'

const Review = () => {
  return (
    <div style={{marginBottom:'-150px'}}>
        <section
  className="elementor-section elementor-top-section elementor-element elementor-element-3398a20 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
  data-id="3398a20"
  data-element_type="section"
>
  <div className="elementor-container elementor-column-gap-no">
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-109d0cf"
      data-id="109d0cf"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-81210b4 elementor-widget elementor-widget-testimonials"
          data-id="81210b4"
          data-element_type="widget"
          data-settings='{"testimonial_bg":"white_bg"}'
          data-widget_type="testimonials.default"
        >
          <div className="elementor-widget-container">
            <section className="testimonials__area-2 fix p-relative pt-120 pb-120">
              <div className="testimonials__shape">
                <img
                  decoding="async"
                  className="t-shape-1 d-none d-sm-block"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-1.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-2"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-2.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-3"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-3.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-4"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-4.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-5 d-none d-lg-block"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-5.png"
                  alt="image"
                />
              </div>
              <div className="container">
                <div className="row mb-65">
                  <div className="col-xl-12">
                    <div className="testimonials__title text-center">
                      <h4
                        className="section__sub-title wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp"
                        }}
                      >
                        Testimonials{" "}
                      </h4>
                      <h2
                        className="section__title wow fadeInUp"
                        data-wow-delay=".3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp"
                        }}
                      >
                        <span>What people say</span>
                      </h2>
                      <p
                        className="wow fadeInUp"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInUp"
                        }}
                      >
                        "Listen to What People Say."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                   <ReviewsMob/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Review