import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.name || !formData.email || !formData.mobile || !formData.message) {
      setErrorMessage("Please fill in all the required fields.");
      return; // Stop further execution
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("name", formData.name);
    urlencoded.append("email", formData.email);
    urlencoded.append("mobile", formData.mobile);
    urlencoded.append("message", formData.message);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://formapi-zvb5.onrender.com/form", requestOptions)
      .then(response => response.text())
      .then(result => {console.log(result)
        setErrorMessage("");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: ""
        });})
      .catch(error => console.log('error', error));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      // Only allow alphabets and spaces in the name
      if (/^[a-zA-Z ]*$/.test(value) || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    }
     else if (name === "mobile") {
      // Only allow 10-digit numbers in the mobile field
      if (/^\d{0,10}$/.test(value) || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  return (
    <div className="col-xl-7 offset-xl-5 col-lg-8 offset-lg-4 col-md-10 offset-md-2">
      <div className="contact__form contact__form-2">
        <div className="contact__form-title mb-40">
          <h4>Send us a message</h4>
        </div>
        <div className="contact__form-content">
          <div className="wpcf7 no-js" id="wpcf7-f10-p82-o1" lang="en-US" dir="ltr">
            <div className="screen-reader-response">
              <p role="status" aria-live="polite" aria-atomic="true" />
              <ul />
            </div>
            <form
              onSubmit={handleSubmit}
              action="/wp/maxdi/contact/#wpcf7-f10-p82-o1"
              method="post"
              className="wpcf7-form init"
              aria-label="Contact form"
              noValidate="novalidate"
              data-status="init"
            >
              <div style={{ display: 'none' }}>
                <input type="hidden" name="_wpcf7" defaultValue={10} />
                <input type="hidden" name="_wpcf7_version" defaultValue="5.8" />
                <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f10-p82-o1" />
                <input type="hidden" name="_wpcf7_container_post" defaultValue={82} />
                <input type="hidden" name="_wpcf7_posted_data_hash" defaultValue="" />
              </div>
              <div className="field-format d-sm-flex justify-content-between mb-40">
                <div className="form-group pr-10 form-group-2">
                  <p>
                    <span className="wpcf7-form-control-wrap" data-name="name">
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text form-control"
                        id="name"
                        aria-invalid="false"
                        placeholder="Your Name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </span>
                  </p>
                </div>
                <div className="form-group pl-10">
                  <p>
                    <span className="wpcf7-form-control-wrap" data-name="email">
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email form-control"
                        id="email"
                        aria-invalid="false"
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div className="form-group mb-40">
                <p>
                  <span className="wpcf7-form-control-wrap" data-name="subject">
                    <input
                      size={40}
                      className="wpcf7-form-control wpcf7-text form-control"
                      id="subject"
                      aria-invalid="false"
                      placeholder="Your Phone Number"
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </span>
                </p>
              </div>
              <div className="form-group mb-50">
                <p>
                  <span className="wpcf7-form-control-wrap" data-name="message">
                    <textarea
                      cols={40}
                      rows={10}
                      className="wpcf7-form-control wpcf7-textarea form-control"
                      id="message"
                      aria-invalid="false"
                      placeholder="Your Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </span>
                </p>
              </div>
              <p>
                <button type="submit" className="m-btn">
                  Send to us
                </button>
              </p>
              {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
              <div className="wpcf7-response-output" aria-hidden="true" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
