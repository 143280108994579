import React from 'react'
import MainPricing from './MainPricing';
import { TfiAngleDoubleRight } from "react-icons/tfi";

function Pricing() {
    const handleCall = () => {
        window.location.href = 'tel:+918123721201'; // Replace with the desired phone number
      };
  return (
    <>
    {/* preloader area start */}
    {/* <div id="pre-loader">
      <div className="pre-loader">
        <span />
        <span />
      </div>
    </div> */}
    {/* preloader area end */}
    {/* back to top start */}
    <div className="progress-wrap">
      <svg
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  
    <div className="fix">
      <div className="side-info">
        <button className="side-info-close">
          <i className="fas fa-times" />
        </button>
        <div className="side-info-content">
          <div className="mobile-menu" />
          <div className="sidebar__contact mb-30">
            <div className="contact-list mb-30">
              <ul>
                <li>
                  <i className="fas fa-map-marker-alt" />
                  66 Broklyn Street, New York United States of America{" "}
                </li>
                <li>
                  <i className="far fa-paper-plane" />
                  <a href="mailto:http://info@example.com">info@example.com</a>
                </li>
                <li>
                  <i className="fas fa-phone" />
                  <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
                </li>
              </ul>
              <div className="sidebar__social">
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#">
                  <i className="fab fa-google" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* side info end */}
    <div className="offcanvas-overlay" />
    {/* mobile menu sidebar end */}
    {/* header end */}
    {/* wrapper-box start */}
    {/* page-title area start */}
    <section className="page__title-area page__title-height fix d-flex align-items-center p-relative  ">
      <div className="page__title-shape">
        <img
          className="page-title-s-1 d-none d-md-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-1.png"
          alt="image"
        />
        <img
          className="page-title-s-3"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-3.png"
          alt="image"
        />
        <img
          className="page-title-s-4"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-4.png"
          alt="image"
        />
        <img
          className="page-title-s-5 wow fadeInDown"
          data-wow-delay="1s"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-5.png"
          alt="image"
        />
        <img
          className="page-title-s-6 wow fadeInUp"
          data-wow-delay=".6s"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-6.png"
          alt="image"
        />
        <img
          className="page-title-s-7 d-none d-md-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-7.png"
          alt="image"
        />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-12">
            <div className="page__title-wrapper">
              <h2 className="page__title-heading">Our Pricing Plans </h2>
              <nav
                aria-label="Breadcrumbs"
                className="breadcrumb-trail breadcrumbs"
              >
                <ul
                  className="trail-items"
                  itemScope=""
                  itemType="http://schema.org/BreadcrumbList"
                >
                  <li
                    itemProp="itemListElement"
                    itemScope=""
                    itemType="http://schema.org/ListItem"
                    className="trail-item trail-begin"
                  >
                    <a
                      href="/"
                      rel="home"
                      itemProp="item"
                    >
                      <span itemProp="name">Home</span>
                    </a>&nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                    <meta itemProp="position" content={1} />
                  </li>
                  <li className="trail-item trail-end">
                    <span>Pricing</span>
                  </li>
                </ul>
              </nav>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* page-title area end */}
   
    {/* lets-work area start */}
   <MainPricing/>
 
  </>
  
  

  );
}

export default Pricing;
