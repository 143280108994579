import React from 'react'
import { TfiAngleDoubleRight } from "react-icons/tfi";


const Services = () => {
  return (
    <>
  {/* preloader area start */}
  {/* <div id="pre-loader">
    <div className="pre-loader">
      <span />
      <span />
    </div>
  </div> */}
  {/* preloader area end */}
  {/* back to top start */}
  <div className="progress-wrap">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
  {/* back to top end */}
  {/* header start */}
  {/* header start */}
 
  {/* header end */}
  {/* side info start */}
  {/* mobile menu sidebar start */}
  <div className="fix">
    <div className="side-info">
      <button className="side-info-close">
        <i className="fas fa-times" />
      </button>
      <div className="side-info-content">
        <div className="mobile-menu" />
        <div className="sidebar__contact mb-30">
          <div className="contact-list mb-30">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt" />
                66 Broklyn Street, New York United States of America{" "}
              </li>
              <li>
                <i className="far fa-paper-plane" />
                <a href="mailto:http://info@example.com">info@example.com</a>
              </li>
              <li>
                <i className="fas fa-phone" />
                <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
              </li>
            </ul>
            <div className="sidebar__social">
              <a href="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
              <a href="#">
                <i className="fab fa-google" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* side info end */}
  <div className="offcanvas-overlay" />
  {/* mobile menu sidebar end */}
  {/* header end */}
  {/* wrapper-box start */}
  {/* page-title area start */}
  <section className="page__title-area page__title-height fix d-flex align-items-center p-relative  ">
    <div className="page__title-shape">
      <img
        className="page-title-s-1 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-1.png"
        alt="image"
      />
      <img
        className="page-title-s-3"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-3.png"
        alt="image"
      />
      <img
        className="page-title-s-4"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-4.png"
        alt="image"
      />
      <img
        className="page-title-s-5 wow fadeInDown"
        data-wow-delay="1s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-5.png"
        alt="image"
      />
      <img
        className="page-title-s-6 wow fadeInUp"
        data-wow-delay=".6s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-6.png"
        alt="image"
      />
      <img
        className="page-title-s-7 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-7.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xxl-12">
          <div className="page__title-wrapper">
            <h2 className="page__title-heading">Services </h2>
            <nav
              aria-label="Breadcrumbs"
              className="breadcrumb-trail breadcrumbs"
            >
              <ul
                className="trail-items"
                itemScope=""
                itemType="http://schema.org/BreadcrumbList"
              >
                <li
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                  className="trail-item trail-begin"
                >
                  <a
                    href="/"
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">Home</span>
                  </a> &nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                  <meta itemProp="position" content={1} />
                </li>
                <li className="trail-item trail-end">
                  <span>Services</span>
                </li>
              </ul>
            </nav>{" "}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* page-title area end */}
  <div
    data-elementor-type="wp-page"
    data-elementor-id={86}
    className="elementor elementor-86"
  >
    <section style={{marginTop:'-80px'}}
      className="elementor-section elementor-top-section elementor-element elementor-element-88cab54 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="88cab54"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ba26466"
          data-id="ba26466"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-f5de2e6 elementor-widget elementor-widget-services"
              data-id="f5de2e6"
              data-element_type="widget"
              data-widget_type="services.default"
            >
              <div className="elementor-widget-container">
                <section className="services__area p-relative fix mt-75 pt-120 pb-90">
                  <div className="services__shape">
                    <img
                      decoding="async"
                      className="services-s-1 wow fadeInDown"
                      data-wow-delay="1.2s"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/services-s-1.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="services-s-2"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/services-s-2.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="services-s-3"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/services-s-3.png"
                      alt="image"
                    />
                  </div>
                  <div className="container">
                    <div className="row mb-55">
                      <div className="col-xl-6">
                        <div className="services__title">
                          <h4
                            className="section__sub-title wow fadeInUp"
                            data-wow-delay=".2s"
                          >
                            Services{" "}
                          </h4>
                          <h2
                            className="section__title wow fadeInUp"
                            data-wow-delay=".3s"
                          >
                            <span>What we do</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-1.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>UI/UX Design</h3>
                              <p>
                              This involves designing user interfaces and user experiences for software applications, websites, and other digital products to ensure they are intuitive and easy to use.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/ux"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-2.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>Web Development</h3>
                              <p>
                              It includes designing and developing websites and web-based applications, ranging from simple informational websites to complex e-commerce platforms.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/web"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-3.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>App Development</h3>
                              <p>
                              This involves developing mobile applications for Android, iOS, and other mobile platforms, as well as cross-platform solutions that work on multiple devices.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/mobile"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-4.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>Digital Marketing</h3>
                              <p>
                              It includes providing digital marketing and SEO services to help businesses and organizations promote their products and services online and increase their online visibility.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/digital"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-5.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>IT Consulting Services</h3>
                              <p>
                              This involves designing user interfaces and user experiences for software applications, websites, and other digital products to ensure they are intuitive and easy to use.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/consult"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="single__bg">
                          <div className="single__service">
                            <div className="single__service-icon">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s-icon-6.png"
                                alt="icon"
                              />
                            </div>
                            <div className="single__service-content">
                              <h3>Software Development</h3>
                              <p>
                              This includes designing, developing, testing, and deploying custom software applications for businesses, organizations, and individuals.
                              </p>
                            </div>
                            <div className="single__service-link">
                              <a
                                href="/software"
                                className="s-btn"
                              >
                                Find Out More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-73eb8af elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="73eb8af"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2336f58"
          data-id="2336f58"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-4ce7840 elementor-widget elementor-widget-maxdi"
              data-id="4ce7840"
              data-element_type="widget"
              data-widget_type="maxdi.default"
            >
              <div className="elementor-widget-container">
                <section className="maxdi__area fix p-relative pt-115 pb-145">
                  <div className="maxdi__shape">
                    <img
                      decoding="async"
                      className="m-shape-1 d-none d-sm-block"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-1.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="m-shape-2"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-2.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="m-shape-3"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-3.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="m-shape-4"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-4.png"
                      alt="image"
                    />
                  </div>
                  <div className="container">
                    <div className="row mb-110">
                      <div className="col-xl-12">
                        <div className="maxdi__title text-center">
                          <h4
                            className="section__sub-title wow fadeInUp"
                            data-wow-delay=".2s"
                          >
                            We Are Anisys Technology{" "}
                          </h4>
                          <h2
                            className="section__title wow fadeInUp"
                            data-wow-delay=".4s"
                          >
                            <span>We work to help your brand success</span>
                          </h2>
                          <p className="wow fadeInUp" data-wow-delay=".6s">
                            Draw a line in the sand quick win. My capacity is
                            full. Keep it lean gain alignment we need to
                            future-proof this plan.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="maxdi__counter ">
                          <div className="maxdi__counter-content  text-center">
                            <h2>
                              <span className="counter">500</span>+
                            </h2>
                            <p>Happy Clients</p>
                          </div>
                        </div>
                        <div className="maxdi__counter maxdi__counter-2">
                          <div className="maxdi__counter-content maxdi__counter-content-2 text-center">
                            <h2>
                              <span className="counter">280</span>+
                            </h2>
                            <p>Project Delivered</p>
                          </div>
                        </div>
                        <div className="maxdi__content d-flex">
                          <div className="maxdi__thumb-1">
                            <img
                              decoding="async"
                              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-1.jpg"
                              alt="image"
                            />
                          </div>
                          <div className="maxdi__thumb-2">
                            <img
                              decoding="async"
                              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-2.jpg"
                              alt="image"
                            />
                          </div>
                          <div className="maxdi__thumb-3">
                            <img
                              decoding="async"
                              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-3.jpg"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-aae6078 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="aae6078"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82a5673"
          data-id="82a5673"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-0f027de elementor-widget elementor-widget-faq"
              data-id="0f027de"
              data-element_type="widget"
              data-widget_type="faq.default"
            >
              <div className="elementor-widget-container">
                <section className="faq__area fix p-relative pt-105 pb-60 faq__rs">
                  <div className="faq__shape">
                    <img
                      decoding="async"
                      className="faq-shape-1 d-none d-lg-block"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/faq/faq-s-1.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="faq-shape-2"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/faq/faq-s-2.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="faq-shape-3 d-none d-lg-block"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/faq/faq-s-3.png"
                      alt="image"
                    />
                    <img
                      decoding="async"
                      className="faq-shape-4 d-none d-lg-block"
                      src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/faq/faq-s-4.png"
                      alt="image"
                    />
                  </div>
                  <div className="container">
                    <div className="faq__bg">
                      <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5">
                          <div className="faq__thumb">
                            <div className="faq__thumb-image">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/faq-img.jpg"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-10">
                          <div className="faq__content">
                            <h4 className="section__sub-title">Faq </h4>
                            <h2 className="section__title">
                              <span className="first_title">
                                Freequently asked
                              </span>
                              <span className="second_title">questions</span>
                            </h2>
                            <div className="faq__wrapper">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-content">
                                  <h2
                                    className="accordion-header"
                                    id="heading0"
                                  >
                                    <button
                                      className="accordion-button "
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse0"
                                      aria-expanded="true"
                                      aria-controls="collapse0"
                                    >
                                      How To Gain Knowledge About SEO?{" "}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse0"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="heading0"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                      Explore our curated SEO courses, guides, and expert support. Access exclusive tools, resources, and community events for hands-on learning and staying updated on the latest SEO trends.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-content">
                                  <h2
                                    className="accordion-header"
                                    id="heading1"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse1"
                                      aria-expanded="false"
                                      aria-controls="collapse1"
                                    >
                                      Benefit Of Digital Marketing?{" "}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse1"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="heading1"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                      Unlock the potential of your business with Digital Marketing. Reach wider audiences, enhance brand visibility, track performance in real-time, drive targeted traffic, and ultimately boost conversions. Stay ahead in the competitive landscape and foster meaningful customer engagements.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-content">
                                  <h2
                                    className="accordion-header"
                                    id="heading2"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse2"
                                      aria-expanded="false"
                                      aria-controls="collapse2"
                                    >
                                      Business Development Most Reasons?{" "}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse2"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="heading2"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                      Business development fuels growth by expanding networks, fostering partnerships, and unlocking new opportunities. It enhances market reach, drives revenue, facilitates innovation, and cultivates resilience in the ever-evolving market landscape. Effective business development aligns strategies with market needs, amplifies brand presence, and builds sustainable relationships, ultimately propelling the company towards success.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-content">
                                  <h2
                                    className="accordion-header"
                                    id="heading3"
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse3"
                                      aria-expanded="false"
                                      aria-controls="collapse3"
                                    >
                                      Improve Performance and Efficiency?{" "}
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse3"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="heading3"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <p>
                                      Improving performance and efficiency involves streamlining processes, optimizing resources, and implementing strategies that enhance productivity. This might include leveraging technological advancements, refining workflows, providing comprehensive training, embracing automation, and continuously evaluating and refining methodologies. By fostering a culture of innovation, setting clear goals, and encouraging continuous improvement, businesses can significantly boost their performance and operational efficiency
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div>
  {/* lets-work area start */}
 
  {/* lets-work area end */}
  {/* footer area start */}
 
  {/* footer area end */}
</>

  )
}

export default Services