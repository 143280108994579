import React from 'react'
import ani from '../images/ani.jpg'

const Header = () => {
  return (
    <div>
         <header>
      <div style={{backgroundColor:'white'}}
        id="header-sticky"
        className="header__area header__area-2 header__transparent"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-6 col-6">
              <div className="logo">
                <a
                  className="standard-logo"
                  href="https://thememaster.net/wp/maxdi/"
                >
                     <img src={ani} />
                  {/* <img
                    src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/logo/Logo-white.svg"
                    alt="logo"
                  /> */}
                </a>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6 d-none d-xl-block">
              <div className="main-menu main-menu-2 text-end">
                <nav id="mobile-menu">
                  <ul id="menu-main-menu" >
                    <li 
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-111"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children dropdown active menu-item-111 nav-items"
                    >
                      <a
                        title="Home"
                        href="/"
                        className="nav-link "
                        style={{color:'black'}}
                      >
                        Home
                      </a>
                     
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-94"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-94 nav-items"
                    >
                      <a
                        title="About"
                        href="/about"
                        className="nav-link"
                        style={{color:'black'}}
                      >
                        About 
                      </a>
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-112"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-112 nav-items"
                    >
                      <a title="services" href="/services" className="nav-link"  style={{color:'black'}}>
                        Services
                      </a>
                      {/* <ul className="submenu" role="menu">
                        <li
                          itemScope="itemscope"
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-95"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-95 nav-items"
                        >
                          <a
                            title="Contact"
                            href="https://thememaster.net/wp/maxdi/contact/"
                            className="dropdown-items"
                          >
                            Contact
                          </a>
                        </li>
                        <li
                          itemScope="itemscope"
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-93"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-93 nav-items"
                        >
                          <a
                            title="Blog"
                            href="https://thememaster.net/wp/maxdi/blog/"
                            className="dropdown-items"
                          >
                            Blog
                          </a>
                        </li>
                        <li
                          itemScope="itemscope"
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-113"
                          className="menu-item menu-item-type-post_type menu-item-object-post menu-item-113 nav-items"
                        >
                          <a
                            title="Blog Details"
                            href="https://thememaster.net/wp/maxdi/delivering-the-best-digital-marketing-solution-creative-team/"
                            className="dropdown-items"
                          >
                            Blog Details
                          </a>
                        </li>
                        <li
                          itemScope="itemscope"
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-99"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-99 nav-items"
                        >
                          <a
                            title="Team"
                            href="https://thememaster.net/wp/maxdi/team/"
                            className="dropdown-items"
                          >
                            Team
                          </a>
                        </li>
                        <li
                          itemScope="itemscope"
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-100"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-100 nav-items"
                        >
                          <a
                            title="Team Details"
                            href="https://thememaster.net/wp/maxdi/team-details/"
                            className="dropdown-items"
                          >
                            Team Details
                          </a>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-98"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-98 nav-items"
                    >
                      <a
                        title="pricing"
                        href="/pricing"
                        className="nav-link"
                        style={{color:'black'}}
                      >
                        Pricing
                      </a>
                     
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-97"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-97 nav-items"
                    >
                      <a
                        title="contact"
                        href="/contact"
                        className="nav-link"
                        style={{color:'black'}}
                      >
                        Contact Us
                      </a>
                    
                    </li>
                    <li
                      itemScope="itemscope"
                      itemType="https://www.schema.org/SiteNavigationElement"
                      id="menu-item-97"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-97 nav-items"
                    >
                      <a
                        title="careers"
                        href="/career"
                        className="nav-link"
                        style={{color:'black'}}
                      >
                        Careers
                      </a>
                    
                    </li>
                  </ul>{" "}
                </nav>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-7 col-md-6 col-6">
              <div className="header__right text-end d-flex align-items-center justify-content-end">
                <div className="d-none d-md-block">
                  <a
                    className="m-btn"
                    href="/requirement"
                  >
                    Get A Quote
                  </a>
                </div>
                <div className="side-menu-icon icon-white d-xl-none">
                  <button className="side-toggle">
                    <i className="fa-solid fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </div>
  )
}

export default Header