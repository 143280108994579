
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
        const phoneNumber = '+918123721201'; // Replace with your WhatsApp phone number
        const message = encodeURIComponent('Hello! I have a question.'); // Replace with your desired default message
        const url = `https://wa.me/${phoneNumber}?text=${message}`;
    
        window.open(url, '_blank');
      };

  return (
    <div
      style={{
        position: isFixed ? 'fixed' : 'relative',
        bottom: '18px',
        left: '40px',
        zIndex: 9999,
      }}
    >
      <button
        onClick={handleClick}
        style={{
          border: 'none',
          background: '#25D366',
          padding: 0,
          cursor: 'pointer',
          borderRadius: '50%',
          width: '70px',
          height: '70px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <FaWhatsapp size={42} style={{ color: '#FFFFFF' }} />
      </button>
    </div>
  );
};

export default WhatsAppButton;

