import React,{useState,useEffect} from 'react'

const NumberList4 = ()=>{
    const[count,setCount]=useState(1)
    useEffect(()=>{
        setTimeout(()=>{
            if(count<96){
                setCount(count+1)
            }
        },60)
    })
    return(
        <>
          <p  style={{fontSize:'42px',color:'white',fontWeight:'bold'}}>   +{count}</p>
        </>
    )
}

export default NumberList4