import React from 'react'

const Estimate = () => {
  return (
    <div>
         <section className="lets-work__area p-relative pt-165 pb-160">
      <div className="lets-work__shape">
        <img
          className="lets-work-shape-1 d-none d-lg-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-1.png"
          alt="image"
        />
        <img
          className="lets-work-shape-2 d-none d-lg-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-2.png"
          alt="image"
        />
        <img
          className="lets-work-shape-3 d-none d-sm-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-3.png"
          alt="image"
        />
        <img
          className="lets-work-shape-4 d-none d-sm-block"
          src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-4.png"
          alt="image"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="lets-work__title text-center">
              <h4
                className="section__sub-title wow fadeInUp"
                data-wow-delay=".2s"
              >
                Let’s Work Together{" "}
              </h4>
              <h2 className="section__title wow fadeInUp" data-wow-delay=".3s">
                <span>Need a successful</span>
                project?{" "}
              </h2>
              <a
                className="m-btn mt-35 wow fadeInUp"
                data-wow-delay=".6s"
                href="/contact"
              >
                Estimate Project
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Estimate