import React from "react";

const Map = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.77057022791!2d77.6434625741885!3d12.98652101455276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1744819f8cf7%3A0xb67a1afce31d0b3a!2sAnisys%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1688114722699!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: '0' }} 
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Map;
