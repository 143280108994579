import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/about/About';
import Header from './common/Header';
import Footer from './common/Footer';
import Services from './components/service/Services';
import Contact from './components/contact/Contact';
import UxService from './components/service/UxService';
import SoftwareService from './components/service/SoftwareService';
import MobileService from './components/service/MobileService';
import WebService from './components/service/WebService';
import DigitalService from './components/service/DigitalService';
import ConsultService from './components/service/ConsultService';
import Pricing from './components/pricing/Pricing';
import Career from './components/career/Career';
import Estimate from './common/Estimate';
import Requirement from './components/contact/Requirement';
import MobileSidebar from './common/MobileSidebar';

function App() {
  return (
   <>
   <div className="d-none d-sm-block"><Header/></div>
   <MobileSidebar/>
   <Router>
      <Routes>
      <Route path="/" element={<Home/>}></Route>

   <Route path="/about" element={<About/>}></Route>
   <Route path="/services" element={<Services/>}></Route>
   <Route path="/contact" element={<Contact/>}></Route>
   <Route path="/pricing" element={<Pricing/>}></Route>
   <Route path="/career" element={<Career/>}></Route>
<Route path="/requirement" element={<Requirement/>}></Route>
   <Route path="/ux" element={<UxService/>}></Route>
   <Route path="/software" element={<SoftwareService/>}></Route>
   <Route path="/mobile" element={<MobileService/>}></Route>
   <Route path="/web" element={<WebService/>}></Route>
   <Route path="/digital" element={<DigitalService/>}></Route>
   <Route path="/consult" element={<ConsultService/>}></Route>







   </Routes>
   </Router>
   <Estimate/>
   <Footer/>
   </>


  );
}

export default App;
