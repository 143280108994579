import React,{useState,useEffect} from 'react'

const NumberList3 = ()=>{
    const[count,setCount]=useState(1)
    useEffect(()=>{
        setTimeout(()=>{
            if(count<2000){
                setCount(count+1)
            }
        },2)
    })
    return(
        <>
          <p  style={{fontSize:'42px',color:'white',fontWeight:'bold'}}>   +{count}</p>
        </>
    )
}

export default NumberList3