import React from 'react'
import ani from '../images/ani.jpg'
import WhatsAppButton from './WhatsAppButton'
import PhoneCall from './PhoneCall'
import { BsFacebook, BsFillTelephoneFill, BsInstagram, BsSendFill, BsYoutube ,BsFillGeoAltFill} from 'react-icons/bs'
import { SiInvision } from "react-icons/si";

const Footer = () => {
  return (
    <>
       <PhoneCall phoneNumber="+918123721201" />

<WhatsAppButton/>
 
    <footer>
      <div className="footer__area fix p-relative">
        <div className="footer__shape">
     
          <img
            className="footer-shape-1 wow fadeInDown"
            data-wow-delay="1s"
            src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/footer/footer-s-1.png"
            alt="image"
          />
          <img
            className="footer-shape-2 wow fadeInUp"
            data-wow-delay="1.6s"
            src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/footer/footer-s-2.png"
            alt="image"
          />
        </div>
        <div className="footer__top pt-200 pb-40">
          <div className="container">
            <div className="row mb-145">
              <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                <div className="footer__newsletter">
                  <h4
                    className="section__sub-title wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    join our community{" "}
                  </h4>
                  <h2
                    className="section__title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span>Subscribe for newsletter</span>
                  </h2>
                </div>
              </div>
              <div className="col-xxl-5 offset-xxl-1 col-xl-5 col-lg-4 col-md-4">
                <div className="widget_text footer__widget footer__widget-link">
                  <div className="textwidget custom-html-widget">
                    <div className="footer__subscribe">
                      <form action="#">
                        <div className="footer__subscribe-input">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email address"
                            />
                          </div>
                          <button type="submit">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-10 col-sm-10">
                <div className="footer__widget wow fadeIn" data-wow-delay=".5s">
                  <div className="footer__widget-logo">
                  {/* <img src={ani} /> */}
                    {/* <img
                      src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/Logo-footer.svg"
                      alt="logo"
                    /> */}
                    <p>
                    Old Baiyyappanahalli, Extension, Bengaluru, Karnataka 560033
                    </p>
                  </div>
                  <div className="footer__widget-social">
                    <a href="#">
                    <BsFacebook/>
                    </a>
                    <a href="#">
                      <SiInvision/>
                    </a>
                    <a href="#">
                      <BsInstagram/>
                    </a>
                    <a href="#">
                      <BsYoutube/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                <div className="footer__widget wow fadeIn" data-wow-delay=".7s">
                  <div className="footer__widget-link">
                    <div className="footer__widget-title">
                      <h5>Our Services</h5>
                    </div>
                    <div className="menu-footer-menu-1-container">
                      <ul id="menu-footer-menu-1" className="menu">
                        <li
                          id="menu-item-101"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-101"
                        >
                          <a href="#">Development</a>
                        </li>
                        <li
                          id="menu-item-102"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-102"
                        >
                          <a href="#">Marketing</a>
                        </li>
                        <li
                          id="menu-item-103"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-103"
                        >
                          <a href="#">UI/UX Design</a>
                        </li>
                        <li
                          id="menu-item-104"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-104"
                        >
                          <a href="#">E-Commerce</a>
                        </li>
                        <li
                          id="menu-item-105"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-105"
                        >
                          <a href="#">IT Consulting Service</a>
                        </li>
                        <li
                          id="menu-item-105"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-105"
                        >
                          <a href="#">SEO</a>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                <div
                  className="footer__widget footer__widget-mt-2 wow fadeIn"
                  data-wow-delay=".9s"
                >
                  <div className="footer__widget-link">
                    <div className="footer__widget-title">
                      <h5>Company</h5>
                    </div>
                    <div className="menu-footer-menu-2-container">
                      <ul id="menu-footer-menu-2" className="menu">
                     
                        <li
                          id="menu-item-107"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-107"
                        >
                          <a href="/">Home</a>
                        </li>
                        <li
                          id="menu-item-106"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-106"
                        >
                          <a href="/about">About us</a>
                        </li>
                        <li
                          id="menu-item-108"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-108"
                        >
                          <a href="/services">Services</a>
                        </li>
                        <li
                          id="menu-item-109"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-109"
                        >
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li
                          id="menu-item-110"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-110"
                        >
                          <a href="/contact">Contact Us</a>
                        </li>
                        <li
                          id="menu-item-106"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-106"
                        >
                          <a href="/career">Careers</a>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-10">
                <div
                  className="footer__widget footer__widget-mt wow fadeIn"
                  data-wow-delay="1.2s"
                >
                  <div className="widget_text footer__widget-link">
                    <div className="footer__widget-title">
                      <h5>Stay Connected</h5>
                    </div>
                    <div className="textwidget custom-html-widget">
                      <div className="footer__widget-info">
                        <div className="footer__widget-address">
                        <div className="footer__widget-phone d-flex align-items-center">
                          <BsFillTelephoneFill style={{color:'#7432ff'}}/>&nbsp;
                          <p>
                            <a href="tel:+1166442200">+918123721201</a>
                          </p>
                        </div>
                        <div className="footer__widget-email d-flex align-items-center" style={{marginTop:'-20px'}}>
                          <BsSendFill style={{color:'#7432ff'}}/>&nbsp;
                          <p>
                            <a href="mailto:info@example.com">corporate@anisystech.com</a>
                          </p>
                        </div>
                        {/* <div className="footer__widget-email d-flex align-items-center mt-3" >
                          <BsFillGeoAltFill style={{color:'#7432ff',fontSize:'30px'}}/>&nbsp;
                          <p>
                            <a href="mailto:info@example.com">Old Baiyyappanahalli, Extension, Bengaluru, Karnataka 560033</a>
                          </p>
                        </div> */}
                          <h4 className='text-white mt-5' >
                          Opening Hours
                          </h4>
                          <p>Monday - Friday ( 09.15 - 07.00 )</p>
                         <p  style={{marginTop:'-10px'}}>Saturday - Sunday ( Closed )</p>
                        </div>
                       
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer__copyright">
                  <p>Copyright 2023 © Anisys Technology Pvt Ltd All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer