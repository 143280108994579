import React, { useEffect, useState } from 'react'
import { TfiAngleDoubleRight } from "react-icons/tfi";


const Career = () => {
    const[key,setKey]=useState(true)
    const[qual,setQual]=useState(false)
    const[data,setData]=useState(false)

   const keyFun = ()=>{
    setKey(true)
    setQual(false)
    setData(false)
   }

   const qualFun = ()=>{
    setQual(true)
    setKey(false)
    setData(false)

   }

   const dataFun = ()=>{
    setData(true)
    setQual(false)
    setKey(false)
   }
  return (
    <div>
        <>
  
  {/* back to top start */}
  <div className="progress-wrap">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>

  <div className="fix">
    <div className="side-info">
      <button className="side-info-close">
        <i className="fas fa-times" />
      </button>
      <div className="side-info-content">
        <div className="mobile-menu" />
        <div className="sidebar__contact mb-30">
          <div className="contact-list mb-30">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt" />
                66 Broklyn Street, New York United States of America{" "}
              </li>
              <li>
                <i className="far fa-paper-plane" />
                <a href="mailto:http://info@example.com">info@example.com</a>
              </li>
              <li>
                <i className="fas fa-phone" />
                <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
              </li>
            </ul>
            <div className="sidebar__social">
              <a href="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
              <a href="#">
                <i className="fab fa-google" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* side info end */}
  <div className="offcanvas-overlay" />

  <section className="page__title-area page__title-height fix d-flex align-items-center p-relative  ">
    <div className="page__title-shape">
      <img
        className="page-title-s-1 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-1.png"
        alt="image"
      />
      <img
        className="page-title-s-3"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-3.png"
        alt="image"
      />
      <img
        className="page-title-s-4"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-4.png"
        alt="image"
      />
      <img
        className="page-title-s-5 wow fadeInDown"
        data-wow-delay="1s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-5.png"
        alt="image"
      />
      <img
        className="page-title-s-6 wow fadeInUp"
        data-wow-delay=".6s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-6.png"
        alt="image"
      />
      <img
        className="page-title-s-7 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-7.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xxl-12">
          <div className="page__title-wrapper">
            <h2 className="page__title-heading">Careers </h2>
            <nav
              aria-label="Breadcrumbs"
              className="breadcrumb-trail breadcrumbs"
            >
              <ul
                className="trail-items"
                itemScope=""
                itemType="http://schema.org/BreadcrumbList"
              >
                <li
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                  className="trail-item trail-begin"
                >
                  <a
                    href="https://thememaster.net/wp/maxdi/"
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">Home</span>
                  </a>&nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                  <meta itemProp="position" content={1} />
                </li>
                <li className="trail-item trail-end">
                  <span>Careers</span>
                </li>
              </ul>
            </nav>{" "}
          </div>
        </div>
      </div>
    </div>
  </section>

  <div className='row mt-5'>
    <center> <h2
                            className="section__title wow fadeInUp"
                            data-wow-delay=".3s"
                          >
                            <span>Some of our amazing works</span>
                          </h2></center>
    <div className='col-lg-2'></div>
    <div className='col-lg-8 '>
        <div className='row p-1' style={{border:'1px solid #7432ff',borderRadius:'60px',backgroundColor:'#7432ff'}}>
            <div className='col-lg-4'  style={{textAlign:'center'}}>
                <button onClick={keyFun} className='p-2' style={{borderRadius:'20px',backgroundColor: key ? "black" : "#7432ff",color: key ? 'white':'black',
            fontWeight:'bold'}}> KEY RESPONSIBILITY</button>
              
            </div>
            <div className='col-lg-4'  style={{textAlign:'center'}}>
            <button onClick={qualFun} className='p-2' style={{borderRadius:'20px',backgroundColor: qual ? "black" : "#7432ff",color: qual ? 'white':'black',
            fontWeight:'bold'}}>QUALIFICATIONS</button></div>
            <div className='col-lg-4' style={{textAlign:'center'}}>
            <button onClick={dataFun} className='p-2' style={{borderRadius:'20px',backgroundColor: data ? "black" : "#7432ff",color: data ? 'white':'black',
            fontWeight:'bold'}}>DATABASES</button></div>
        </div>
        {key && 
        <div className='row'>
            <div className='col-lg-6 mt-3'>
            <i  style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>
                  KEY RESPONSIBILITY:</i>

            <h2 className='mt-3'>Conveying Brand Essence in a Few Words.</h2>

            <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> 
              "Develop and implement responsive web applications using modern front-end technologies such as HTML5, CSS3, JavaScript, and popular frameworks like React or Angular. Design and develop server-side applications using back-end technologies like Node.js, Python, or Java. Collaborate with cross-functional teams to gather requirements, analyse user needs, and deliver high-quality software solutions. Optimize application performance, troubleshoot issues, and conduct thorough testing to ensure the reliability and functionality of the software. Stay updated with emerging trends, tools, and techniques in full stack development and recommend improvements to existing systems."</p>
             
                 </div>

                 <div className='col-lg-6 mt-3'>
                    <img src="https://images.pexels.com/photos/4491459/pexels-photo-4491459.jpeg?auto=compress&cs=tinysrgb&w=1600" style={{border:'1px solid transparent',borderRadius:'50px',borderTopRightRadius:'0px'}}/>
                    </div>

        </div>
        }
        {qual && 
         <div className='row'>
         <div className='col-lg-6 mt-3'>
         <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>  QUALIFICATIONS:</i>

         <h2 className='mt-3'>Empowering Success Through Expertise: Qualifications That Define Excellence.</h2>

         <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> 
              "Solid experience in front-end development, including proficiency in HTML, CSS, JavaScript, and relevant frameworks. Strong understanding of back-end development principles and experience with server-side programming languages like Node.js, Python, or Java. Familiarity with front-end frameworks such as React, Angular, or Vue.js, and back-end frameworks like Express, Flask, or Spring. Proficient in working with databases and SQL, with knowledge of relational and non-relational."

</p>
          
              </div>

              <div className='col-lg-6 mt-3'>
                 <img src="https://images.pexels.com/photos/4144923/pexels-photo-4144923.jpeg?auto=compress&cs=tinysrgb&w=1600" style={{border:'1px solid transparent',borderRadius:'80px',borderTopRightRadius:'0px'}}/>
                 </div>

     </div>
        }
  {data && 
     <div className='row'>
     <div className='col-lg-6 mt-3'>
     <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>  DATABASES:</i>

     <h2 className='mt-3'>Unlocking Insights, Powering Possibilities: Your Trusted Database Partner.</h2>

     <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> 
              "Experience with version control systems (e.g., Git) and agile development methodologies. Excellent problem-solving skills, attention to detail, and the ability to work both independently and collaboratively within a team environment."</p>
                   
          </div>

          <div className='col-lg-6 mt-3'>
             <img src="https://images.pexels.com/photos/9588210/pexels-photo-9588210.jpeg?auto=compress&cs=tinysrgb&w=1600" style={{border:'1px solid transparent',borderRadius:'80px',borderTopRightRadius:'0px'}}/>
             </div>

 </div>
  }
    </div>

</div>


  {/* lets-work area end */}

 




</>


    </div>
  )
}

export default Career