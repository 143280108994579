import React from 'react'
import './MainPricing.css'

const MainPricing = () => {
    const handleCall = () => {
        window.location.href = 'tel:+918123721201'; // Replace with the desired phone number
      };
  return (
    <div style={{marginTop:'-110px'}}>
          <section
        className="elementor-section elementor-top-section elementor-element elementor-element-36bd487 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="36bd487"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-388c09d"
            data-id="388c09d"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-d0e640c elementor-widget elementor-widget-pricing"
                data-id="d0e640c"
                data-element_type="widget"
                data-widget_type="pricing.default"
              >
                <div className="elementor-widget-container">
                  <section className="pricing__area fix p-relative mt-105 pt-120 pb-90">
                    <div className="pricing__shape">
                      <img
                        decoding="async"
                        className="p-shape-1"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/pricing/p-s-1.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="p-shape-2 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/pricing/p-s-2.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="p-shape-3 wow zoomIn"
                        data-wow-delay=".8s"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/pricing/p-s-3.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="p-shape-4 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/pricing/p-s-4.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="p-shape-5"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/pricing/p-s-5.png"
                        alt="image"
                      />
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-sm-12">
                          <div className="pricing__title text-center">
                            <h4
                              className="section__sub-title wow fadeInUp"
                              data-wow-delay=".2s"
                            >
                              Pricing Plan{" "}
                            </h4>
                            <h2
                              className="section__title wow fadeInUp"
                              data-wow-delay=".3s"
                            >
                              <span>Find the Right Plan</span>
                            </h2>
                            <p className="wow fadeInUp" data-wow-delay=".5s">
                              Draw a line in the sand quick win. My capacity is
                              full. Keep it lean gain alignment we need to
                              future-proof this plan.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="pricing__tab">
                        <div className="pricing__button text-center">
                          <ul
                            className="nav pricing__nav mb-40"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#home1"
                                role="tab"
                                aria-controls="home1"
                                aria-selected="true"
                              >
                               Website Design (static)
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                              >
                               WordPress / React Plans
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                className="nav-link"
                                id="profile-tab1"
                                data-bs-toggle="tab"
                                data-bs-target="#profile1"
                                role="tab"
                                aria-controls="profile1"
                                aria-selected="false"
                              >
                              Dynamic Plan (E-Commerce Website)
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane active"
                            id="home1"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                                <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Basic</h3>
                                    <p>A beautiful, simple website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 9000<span>/monthly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                      <li>Free Hosting</li>
                                      <li>Free SSL</li>
                                      <li>✕ Demo Website</li>
                                      <li>✕ Custome Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>✕ Live Chat</li>
                                      <li>Call Button</li>
                                      <li>✕ Whatsapp Button</li>
                                      <li>✕ Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 3 Pages</li>
                                      <li>1 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"  onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6"  >
                                <div className="pricing__box active mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Standard</h3>
                                    <p>Creative design, standard website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 12000<span>/monthly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Free SSL</li>
                                      <li>✕ Demo Website</li>
                                      <li>✕ Custome Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>✕ Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 5 Pages</li>
                                      <li>3 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6">
                                <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Advance</h3>
                                    <p>Dynamic website exclusive design</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 16000<span>/monthly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custome Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 8 Pages</li>
                                      <li>6 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6">
                                <div className="pricing__box  mb-30" style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Premium</h3>
                                    <p>Dynamic website Premium design</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 22000<span>/monthly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custome Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 12 Pages</li>
                                      <li>8 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"  onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                               <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Basic</h3>
                                    <p>A beautiful, simple website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 14000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>✕ Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>✕ Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 4 Pages</li>
                                      <li>3 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"  onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                                <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Standard</h3>
                                    <p>A beautiful, standard website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 18000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 6 Pages</li>
                                      <li>6 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                               <div className="pricing__box active mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Advanced</h3>
                                    <p>A beautiful, advance website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 25000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 12 Pages</li>
                                      <li>8 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                               <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Premium</h3>
                                    <p>A beautiful, premium website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 35000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 15 Pages</li>
                                      <li>12 Month Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane"
                            id="profile1"
                            role="tabpanel"
                            aria-labelledby="profile-tab1"
                          >
                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                               <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Basic</h3>
                                    <p>A beautiful, simple website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 45000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 6 Pages</li>
                                      <li>1 Year Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                                <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Standard</h3>
                                    <p>A beautiful, standard website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 70000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 12 Pages</li>
                                      <li>1 Year Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                               <div className="pricing__box  mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Advance</h3>
                                    <p>A beautiful, advance website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2>
                                    ₹ 120000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 18 Pages</li>
                                      <li>1 Year Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"  onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-6" >
                                <div className="pricing__box active mb-30"  style={{padding:'10px'}}>
                                  <div className="pricing__header">
                                    <h3>Premium</h3>
                                    <p>A beautiful, premium website</p>
                                  </div>
                                  <div className="pricing__amount">
                                    <h2 style={{fontSize:'40px'}}>
                                    ₹ 250000<span>/yearly</span>
                                    </h2>
                                  </div>
                                  <div className="pricing__item">
                                    <ul>
                                    <li>Free Hosting</li>
                                      <li>Demo Website</li>
                                      <li>Dynamic Website</li>
                                      <li>Free SSL</li>
                                      <li>Demo Website</li>
                                      <li>Custom Editing</li>
                                      <li>Responsive</li>
                                      <li>User Friendly</li>
                                      <li>Basic Design</li>
                                      <li>Live Chat</li>
                                      <li>Call Button</li>
                                      <li>Whatsapp Button</li>
                                      <li>Contact Form</li>
                                      <li>Live Website</li>
                                      <li>Upto 24 Pages</li>
                                      <li>1 Year Support</li>
                                    </ul>
                                  </div>
                                  <div className="pricing__link">
                                    <a className="m-btn"   onClick={handleCall} >
                                      Call Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default MainPricing