import React from 'react'
import Review from '../../review/Review'
import Companies from '../companies/Companies';
import MainPricing from '../pricing/MainPricing';
import { BsArrowRight, BsArrowUp, BsFillClockFill } from 'react-icons/bs';
import NumberList2 from '../about/NumberList2';
import NumberList4 from '../about/NumberList4';
import { isMobile } from 'react-device-detect';
const Home = () => {
 const mobmain ={marginTop:'-150px'}
 const deskmain ={}

  return (
    <>
    {/* preloader area start */}
    <div id="pre-loader">
      <div className="pre-loader">
        <span />
        <span />
      </div>
    </div>
    {/* preloader area end */}
    {/* back to top start */}
    <div className="progress-wrap" >
      {/* <BsArrowUp/> */}
      
      <svg 
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
    
 
    <div className="fix">
      <div className="side-info">
        <button className="side-info-close">
          <i className="fas fa-times" />
        </button>
        <div className="side-info-content">
          <div className="mobile-menu" />
          <div className="sidebar__contact mb-30">
            <div className="contact-list mb-30">
              <ul>
                <li>
                  <i className="fas fa-map-marker-alt" />
                  66 Broklyn Street, New York United States of America{" "}
                </li>
                <li>
                  <i className="far fa-paper-plane" />
                  <a href="mailto:http://info@example.com">info@example.com</a>
                </li>
                <li>
                  <i className="fas fa-phone" />
                  <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
                </li>
              </ul>
              <div className="sidebar__social">
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#">
                  <i className="fab fa-google" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* side info end */}
    <div className="offcanvas-overlay" />
    {/* mobile menu sidebar end */}
    {/* header end */}
    {/* wrapper-box start */}
    <div
      data-elementor-type="wp-page"
      data-elementor-id={74}
      className="elementor elementor-74"
    >
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-067ec98 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="067ec98"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-906c9ed"
            data-id="906c9ed"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-d6137a8 elementor-widget elementor-widget-hero-creative"
                data-id="d6137a8"
                data-element_type="widget"
                data-widget_type="hero-creative.default"
              >
                <div className="elementor-widget-container">
                  <section className="hero__area-2 fix p-relative">
                    <div className="hero__shape-2">
                      <img
                        decoding="async"
                        className="hero2-s-1"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-1.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="hero2-s-2"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-2.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="hero2-s-3 d-none d-xl-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-3.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="hero2-s-4 d-none d-lg-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-4.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="hero2-s-5 wow zoomIn"
                        data-wow-delay=".8s"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-5.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="hero2-s-6 wow zoomIn"
                        data-wow-delay="1.7s"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/hero2/hero2-s-6.png"
                        alt="image"
                      />
                    </div>
                    <div className="hero__height hero__pt d-flex align-items-center">
                      <div className="container">
                        <div className="row align-items-center" style={isMobile?mobmain:deskmain}>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10">
                            <div className="hero__content-2">
                              <h4
                                className="section__sub-title wow fadeInUp"
                                data-wow-delay=".2s"
                              >
                               Welcome to Anisys Technology{" "}
                              </h4>
                              <h2
                                className="hero__title hero__title-2 wow fadeInUp"
                                data-wow-delay=".3s"
                              >
                                <span className="first_title">
                                Empowering Your Digital Future 
                                </span>
                                <span className="second_title">
                                with latest software & technologies
                                </span>
                              </h2>
                              {/* <p className="wow fadeInUp" data-wow-delay=".6s">
                                The carbon in our apple pies extraordinary claims
                                require extra evidence permanence of the stars
                                energy.
                              </p> */}
                              <a
                                href="/contact"
                                className="m-btn mt-35 wow fadeInUp"
                                data-wow-delay=".6s"
                              >
                                Get Started
                              </a>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10">
                            <div className="hero__thumb-2 text-end">
                              <div className="hero__thumb-2-image">
                                <img
                                  decoding="async"
                                  src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/hero-img-2.png"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hero__services">
                      <div className="container">
                        <div className="row mb-55">
                          <div className="col-xl-6">
                            <div className="services__title">
                              <h4
                                className="section__sub-title wow fadeInUp"
                                data-wow-delay=".2s"
                              >
                                Services{" "}
                              </h4>
                              <h2
                                className="section__title wow fadeInUp"
                                data-wow-delay=".3s"
                              >
                                <span>What we do</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single__bg single__bg-pink">
                              <div className="single__service single__service-2">
                                <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s2-icon-1.png"
                                    alt="icon"
                                  />
                                </div>
                                <div className="single__service-content single__service-content-2">
                                  <h3>UI/UX Design</h3>
                                  <p>
                                  This involves designing user interfaces and user experiences for software applications, websites, and other digital products to ensure they are intuitive and easy to use.
                                  </p>
                                </div>
                                <div className="single__service-link">
                                  <a
                                    href="/ux"
                                    className="s-btn s-btn-pink"
                                  >
                                    Find Out More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single__bg single__bg-yellow">
                              <div className="single__service single__service-2">
                                <div className="single__service-icon single__service-icon-yellow">
                                  <img
                                    decoding="async"
                                    src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s2-icon-2.png"
                                    alt="icon"
                                  />
                                </div>
                                <div className="single__service-content single__service-content-2">
                                  <h3>Web Development</h3>
                                  <p>
                                  It includes designing and developing websites and web-based applications, ranging from simple informational websites to complex e-commerce platforms.
                                  </p>
                                </div>
                                <div className="single__service-link">
                                  <a
                                    href="/web"
                                    className="s-btn s-btn-yellow"
                                  >
                                    Find Out More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single__bg single__bg-green">
                              <div className="single__service single__service-2">
                                <div className="single__service-icon single__service-icon-green">
                                  <img
                                    decoding="async"
                                    src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/s2-icon-3.png"
                                    alt="icon"
                                  />
                                </div>
                                <div className="single__service-content single__service-content-2">
                                  <h3>App Development</h3>
                                  <p>
                                  This involves developing mobile applications for Android, iOS, and other mobile platforms, as well as cross-platform solutions that work on multiple devices.
                                  </p>
                                </div>
                                <div className="single__service-link">
                                  <a
                                    href="/mobile"
                                    className="s-btn s-btn-green"
                                  >
                                    Find Out More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-3719bdf elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="3719bdf"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-acce20d"
            data-id="acce20d"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-a127077 elementor-widget elementor-widget-maxdi"
                data-id="a127077"
                data-element_type="widget"
                data-widget_type="maxdi.default"
              >
                <div className="elementor-widget-container">
                  <section className="maxdi__area fix p-relative pt-115 pb-145">
                    <div className="maxdi__shape">
                      <img
                        decoding="async"
                        className="m-shape-1 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-1.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="m-shape-2"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-2.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="m-shape-3"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-3.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="m-shape-4"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-4.png"
                        alt="image"
                      />
                    </div>
                    <div className="container">
                      <div className="row mb-110">
                        <div className="col-xl-12">
                          <div className="maxdi__title text-center">
                            <h4
                              className="section__sub-title wow fadeInUp"
                              data-wow-delay=".2s"
                            >
                              We Are Anisys Technology{" "}
                            </h4>
                            <h2
                              className="section__title wow fadeInUp"
                              data-wow-delay=".4s"
                            >
                              <span>We work to help your brand success</span>
                            </h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">
                              Draw a line in the sand quick win. My capacity is
                              full. Keep it lean gain alignment we need to
                              future-proof this plan.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="maxdi__counter ">
                            <div className="maxdi__counter-content  text-center">
                              <h2>
                                <NumberList2/>
                                {/* <span className="counter">500</span>+ */}
                              </h2>
                              <p>Happy Clients</p>
                            </div>
                          </div>
                          <div className="maxdi__counter maxdi__counter-2">
                            <div className="maxdi__counter-content maxdi__counter-content-2 text-center">
                              <h2>
                                <NumberList4/>
                                {/* <span className="counter">280</span>+ */}
                              </h2>
                              <p>Project Delivered</p>
                            </div>
                          </div>
                          <div className="maxdi__content d-flex">
                            <div className="maxdi__thumb-1">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-1.jpg"
                                alt="image"
                              />
                            </div>
                            <div className="maxdi__thumb-2">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-2.jpg"
                                alt="image"
                              />
                            </div>
                            <div className="maxdi__thumb-3">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/maxdi-img-3.jpg"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-8f5e084 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="8f5e084"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b009c32"
            data-id="b009c32"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-f03ba21 elementor-widget elementor-widget-portfolio"
                data-id="f03ba21"
                data-element_type="widget"
                data-settings='{"design_style":"style_2","portfolio_style":"square"}'
                data-widget_type="portfolio.default"
              >
                <div className="elementor-widget-container">
                  <section className="portfolio__area fix p-relative pb-90">
                    <div className="portfolio__shape-2">
                      <img
                        decoding="async"
                        className="p-s-1  d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/portfolio/p-s-1.png"
                        alt="img"
                      />
                      <img
                        decoding="async"
                        className="p-s-2"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/portfolio/p-s-2.png"
                        alt="img"
                      />
                      <img
                        decoding="async"
                        className="p-s-3 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/portfolio/p-s-3.png"
                        alt="img"
                      />
                      <img
                        decoding="async"
                        className="p-s-4"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/portfolio/p-s-4.png"
                        alt="img"
                      />
                      <img
                        decoding="async"
                        className="p-s-5 d-none d-xxl-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/portfolio/p-s-5.png"
                        alt="img"
                      />
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="portfolio__title-2 text-center">
                            <h4
                              className="section__sub-title wow fadeInUp"
                              data-wow-delay=".2s"
                            >
                              Our Stages{" "}
                            </h4>
                            <h2
                              className="section__title wow fadeInUp"
                              data-wow-delay=".3s"
                            >
                              <span>Website/App Design Steps</span>
                            </h2>
                          </div>
                          
                        </div>
                      </div>
                      <div className="row grid mt-70">
                        <div className="col-xl-6 col-lg-6 grid-item marketing ui-ux-design">
                          <div className="portfolio__item-2 mb-30">
                            <div className="portfolio__image-2">
                              {/* <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/portfolio-1.jpg"
                                alt="portfolio"
                              /> */}
                            </div>
                            <div className="portfolio__text-2">
                              <h3>
                                <a href="https://thememaster.net/wp/maxdi/ourportfolio/digital-agency/">
                                Contact Us
                                </a>
                              </h3>
                              <img src="https://cdn-icons-png.flaticon.com/128/1055/1055546.png" style={{height:'110px'}}/>

                              <p className='mt-3'>
                              Contact us with any questions or thoughts. We're here to help and eager to hear from you!
                              </p>
                              <p />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 grid-item development">
                          <div className="portfolio__item-2 mb-30">
                            {/* <div className="portfolio__image-2">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/portfolio-6-800x800.jpg"
                                alt="portfolio"
                              />
                            </div> */}
                            <div className="portfolio__text-2">
                              <h3>
                                <a href="https://thememaster.net/wp/maxdi/ourportfolio/creative-home/">
                                Design
                                </a>
                              </h3>
                            <img src='https://cdn-icons-png.flaticon.com/128/5371/5371617.png'/>
                              <p>
                              Craft a welcoming website with intuitive navigation and captivating visuals cross-device user experience.
                              </p>
                              <p />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 grid-item marketing ui-ux-design">
                          <div className="portfolio__item-2 mb-30">
                            {/* <div className="portfolio__image-2">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/p-3.jpg"
                                alt="portfolio"
                              />
                            </div> */}
                            <div className="portfolio__text-2">
                              <h3>
                                <a href="https://thememaster.net/wp/maxdi/ourportfolio/marketing/">
                                Development
                                </a>
                              </h3>
                              <img src='https://cdn-icons-png.flaticon.com/128/4879/4879305.png'/>
                              <p>
                              Website development blends creativity with technical expertise to craft engaging online experiences.
                              </p>
                              <p />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 grid-item branding development">
                          <div className="portfolio__item-2 mb-30">
                            {/* <div className="portfolio__image-2">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/p-1.jpg"
                                alt="portfolio"
                              />
                            </div> */}
                            <div className="portfolio__text-2">
                              <h3>
                                <a href="https://thememaster.net/wp/maxdi/ourportfolio/alisha-finance/">
                                Testing & Delivered
                                </a>
                              </h3>
                          <img src='https://cdn-icons-png.flaticon.com/128/5474/5474137.png'/>
                              <p>
                              Rigorous testing and timely delivery assured for a seamless website experience.
                              </p>
                              <p />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
  className="elementor-section elementor-top-section elementor-element elementor-element-f63fc53 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
  data-id="f63fc53"
  data-element_type="section"
>
  <div className="elementor-container elementor-column-gap-no">
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dfee495"
      data-id="dfee495"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-0299f20 elementor-widget elementor-widget-team"
          data-id="0299f20"
          data-element_type="widget"
          data-widget_type="team.default"
        >
          <div className="elementor-widget-container">
            <section className="team__area fix p-relative pt-115 pb-80 mb-150">
              <div className="team__shape">
                <img
                  decoding="async"
                  className="t-shape-1"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-1.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-2"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-2.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-3"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-3.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="t-shape-4"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/testimonials/t-shape-4.png"
                  alt="image"
                />
              </div>
              <div className="container">
                <div className="row mb-65">
                  <div className="col-xl-12">
                    <div className="team__heading text-center">
                      {/* <h4
                        className="section__sub-title wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp"
                        }}
                      >
                        Team Members{" "}
                      </h4> */}
                      <h2
                        className="section__title wow fadeInUp"
                        data-wow-delay=".3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp"
                        }}
                      >
                        <span>Mobile-Optimized SEO Mastery</span>
                      </h2>
                      <p
                        className="wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp"
                        }}
                      >
                        Get Discovered Everywhere: Mobile-Friendly SEO Strategies for Growth
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 mb-40">
                   
                    <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/5188/5188382.png"
                                    alt="icon"
                                  style={{width:'100%'}}
                                  />
                                </div>
                    <div className="team__content text-center">
                      <a href="https://thememaster.net/wp/maxdi/team-details">
                        <h3 className="team__title">Mobile Friendly</h3>
                      </a>
                      <span className="team__position">Optimizing websites for mobile is vital for engaging users on smartphones and tablets, broadening audience reach through improved layouts and content.</span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 mb-40">
                   
                    <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Icon-2.png"
                                    alt="icon"
                                  style={{width:'100%'}}
                                  />
                                </div>
                    <div className="team__content text-center">
                      <a href="https://thememaster.net/wp/maxdi/team-details">
                        <h3 className="team__title">Responsive</h3>
                      </a>
                      <span className="team__position">Responsive websites adapt to various screens, ensuring a seamless user experience. They use flexible layouts and media queries for optimal functionality and visual appeal.</span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 mb-40">
                
                   
                         <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/9751/9751703.png"
                                    alt="icon"
                                  style={{width:'100%'}}
                                  />
                                </div>
                 
                    <div className="team__content text-center">
                      <a href="https://thememaster.net/wp/maxdi/team-details">
                        <h3 className="team__title">SEO</h3>
                      </a>
                      <span className="team__position">SEO boosts website visibility through keyword optimization, content enhancement, and link building, driving organic traffic and enhancing online presence.</span>
                    </div>
                  </div>
                
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-ffcefef elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="ffcefef"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6408c30"
            data-id="6408c30"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-f14f06e elementor-widget elementor-widget-project"
                data-id="f14f06e"
                data-element_type="widget"
                data-widget_type="project.default"
              >
                <div className="elementor-widget-container">
                  <section className="project__area p-relative pt-130">
                    <div className="project__shape">
                      <img
                        decoding="async"
                        className="project-s-1"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-1.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="project-s-2"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-2.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="project-s-3"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-3.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="project-s-4 d-none d-lg-block wow zoomIn"
                        data-wow-delay=".8s"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/project/p-s-4.png"
                        alt="image"
                      />
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-9 col-lg-10">
                          <div className="project__thumb d-sm-flex">
                            <div className="project__thumb-1">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/project-img-1.jpg"
                                alt="image"
                              />
                            </div>
                            <div className="project__thumb-2">
                              <img
                                decoding="async"
                                src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/project-img-2.jpg"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-7 offset-xl-5 col-lg-9 offset-lg-3 col-md-10 offset-md-2 col-sm-11">
                          <div className="project__wrapper">
                            <div className="project__content">
                              <div className="project__title">
                                <h4
                                  className="section__sub-title wow fadeInUp"
                                  data-wow-delay=".2s"
                                >
                                  8+ Years Of Experience{" "}
                                </h4>
                                <h2
                                  className="section__title wow fadeInUp"
                                  data-wow-delay=".3s"
                                >
                                  <span className="first_title">
                                    Like what you see with
                                  </span>
                                  <span className="second_title">
                                    our online products?
                                  </span>
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                  That's not on the roadmap losing these latest
                                  prospects is like putting socks on an octopus to
                                  give you a heads-up.
                                </p>
                                <a
                                  href="/contact"
                                  className="m-btn mt-35"
                                >
                                  Start a Project
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <Review/>
   <MainPricing/>
    
      <section
  className="elementor-section elementor-top-section elementor-element elementor-element-9a05075 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
  data-id="9a05075"
  data-element_type="section"
>
  <div className="elementor-container elementor-column-gap-no">
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f23b67d"
      data-id="f23b67d"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-6e06275 elementor-widget elementor-widget-customers"
          data-id="6e06275"
          data-element_type="widget"
          data-widget_type="customers.default"
        >
          <div className="elementor-widget-container">
            <section className="customers__area fix p-relative pt-120 pb-60">
              <div className="customers__shape">
                <img
                  decoding="async"
                  className="c-s-1"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/customers/c-s-1.png"
                  alt="image"
                />
                <img
                  decoding="async"
                  className="c-s-2"
                  src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/customers/c-s-2.png"
                  alt="image"
                />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="customers__title text-center">
                      <h4
                        className="section__sub-title wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp"
                        }}
                      >
                        Creative Customers{" "}
                      </h4>
                      <h2
                        className="section__title wow fadeInUp"
                        data-wow-delay=".3s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.3s",
                          animationName: "fadeInUp"
                        }}
                      >
                        <span>Our beloved partners</span>
                      </h2>
                    </div>
                   <Companies/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-463d3b5 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="463d3b5"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3cdfe88"
            data-id="3cdfe88"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-ab06277 elementor-widget elementor-widget-blog"
                data-id="ab06277"
                data-element_type="widget"
                data-widget_type="blog.default"
              >
                <div className="elementor-widget-container">
                  <section className="blog__area fix p-relative pt-60 pb-80">
                    <div className="blog__shape">
                      <img
                        decoding="async"
                        className="blog-shape-1"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/blog/blog-s-1.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="blog-shape-2"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/blog/blog-s-2.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="blog-shape-3 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/blog/blog-s-3.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="blog-shape-4"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/blog/blog-s-4.png"
                        alt="image"
                      />
                      <img
                        decoding="async"
                        className="blog-shape-5 d-none d-sm-block"
                        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/blog/blog-s-5.png"
                        alt="image"
                      />
                    </div>
                    <div className="container">
                      <div className="row mb-65">
                        <div className="col-xl-12">
                          <div className="blog__title text-center">
                            <h4
                              className="section__sub-title wow fadeInUp"
                              data-wow-delay=".2s"
                            >
                              Our Work{" "}
                            </h4>
                            <h2
                              className="section__title wow fadeInUp"
                              data-wow-delay=".3s"
                            >
                              <span>Our latest updates</span>
                            </h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">
                            Our work is a testament to our commitment and expertise, delivering tailored solutions that empower businesses in the digital realm with precision and creativity
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div className="blog__item mb-40">
                            <div className="blog__thumb">
                              <a >
                                <img
                                  decoding="async"
                                  src="https://gitu.net/gituimg/free-xd-templates/live-cricket-score-streaming-ui-with-blue-themes-free-xd-file-u9.jpg"
                                  alt="blog"
                                />
                              </a>
                            </div>
                            <div className="blog__content">
                              <div className="blog__date d-flex align-items-center justify content-between">
                                <BsFillClockFill style={{color:'#1900dbc9'}}/>&nbsp;
                                <p>19 Sep, 2023</p>
                                <a
                                 
                                  className="m-btn blog-btn"
                                >
                                  Cricket App{" "}
                                </a>
                              </div>
                              <h3>
                                <a >
                                purpose to show the information about finished, live and upcoming matches. It will update the live matches information ball by ball{" "}
                                </a>
                              </h3>
                              <div className="blog__link">
                                <a
                                 
                                  className="arrow-btn"
                                >
                                  Continue Reading &nbsp;
                                  <BsArrowRight style={{color:'black'}}/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div className="blog__item mb-40">
                            <div className="blog__thumb">
                              <a >
                                <img
                                  decoding="async"
                                  src="https://i.pinimg.com/originals/1b/4a/3e/1b4a3e4675febdfd7a6cc9cea77db6c1.png"
                                  alt="blog"
                                />
                              </a>
                            </div>
                            <div className="blog__content">
                              <div className="blog__date d-flex align-items-center justify content-between">
                              <BsFillClockFill style={{color:'#1900dbc9'}}/>&nbsp;
                                <p>01 Aug, 2023</p>
                                <a
                                  
                                  className="m-btn blog-btn"
                                >
                                 Education App{" "}
                                </a>
                              </div>
                              <h3>
                                <a >
                                an app that provides courses based on subscription plan. Instructors can upload course details and assign the assignment to complete the course
                                </a>
                              </h3>
                              <div className="blog__link">
                                <a
                                  
                                  className="arrow-btn"
                                >
                                 Continue Reading &nbsp;
                                  <BsArrowRight style={{color:'black'}}/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div className="blog__item mb-40">
                            <div className="blog__thumb">
                              <a >
                                <img
                                  decoding="async"
                                  src="https://colorlib.com/wp/wp-content/uploads/sites/2/ecommerce-wordpress-themes.jpg"
                                  alt="blog"
                                />
                              </a>
                            </div>
                            <div className="blog__content">
                              <div className="blog__date d-flex align-items-center justify content-between">
                              <BsFillClockFill style={{color:'#1900dbc9'}}/>&nbsp;
                                <p>01 Jul, 2023</p>
                                <a
                                 
                                  className="m-btn blog-btn"
                                >
                                  Ecommerce Web{" "}
                                </a>
                              </div>
                              <h3>
                                <a >
                                Experience an all-inclusive e-commerce hub connecting users, shops, and delivery personnel for effortless browsing, buying, and swift, reliable deliveries.
                                </a>
                              </h3>
                              <div className="blog__link">
                                <a
                                  
                                  className="arrow-btn"
                                >
                                  Continue Reading &nbsp;
                                  <BsArrowRight style={{color:'black'}}/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    {/* lets-work area start */}
   
    {/* lets-work area end */}
    {/* footer area start */}
   
    {/* footer area end */}
  </>
  )
}

export default Home