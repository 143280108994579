import React from 'react'
import { TfiAngleDoubleRight } from "react-icons/tfi";


const SoftwareService = () => {
  return (
    <>
  {/* preloader area start */}
  <div id="pre-loader" style={{ display: "none" }}>
    <div className="pre-loader">
      <span />
      <span />
    </div>
  </div>
  {/* preloader area end */}
  {/* back to top start */}
  <div className="progress-wrap">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>

  <div className="fix">
    <div className="side-info">
      <button className="side-info-close">
        <i className="fas fa-times" />
      </button>
      <div className="side-info-content">
        <div className="mobile-menu" />
        <div className="sidebar__contact mb-30">
          <div className="contact-list mb-30">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt" />
                66 Broklyn Street, New York United States of America{" "}
              </li>
              <li>
                <i className="far fa-paper-plane" />
                <a href="mailto:http://info@example.com">info@example.com</a>
              </li>
              <li>
                <i className="fas fa-phone" />
                <a href="tel:http://+00%2066%2044%2022%2011">+00 66 44 22 11</a>
              </li>
            </ul>
            <div className="sidebar__social">
              <a href="#">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
              <a href="#">
                <i className="fab fa-google" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* side info end */}
  <div className="offcanvas-overlay" />
  {/* mobile menu sidebar end */}
  {/* header end */}
  {/* wrapper-box start */}
  {/* page-title area start */}
  <section className="page__title-area page__title-height fix d-flex align-items-center p-relative  ">
    <div className="page__title-shape">
      <img
        className="page-title-s-1 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-1.png"
        alt="image"
      />
      <img
        className="page-title-s-3"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-3.png"
        alt="image"
      />
      <img
        className="page-title-s-4"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-4.png"
        alt="image"
      />
      <img
        className="page-title-s-5 wow fadeInDown"
        data-wow-delay="1s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-5.png"
        alt="image"
        style={{
          visibility: "visible",
          animationDelay: "1s",
          animationName: "fadeInDown"
        }}
      />
      <img
        className="page-title-s-6 wow fadeInUp"
        data-wow-delay=".6s"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-6.png"
        alt="image"
        style={{
          visibility: "visible",
          animationDelay: "0.6s",
          animationName: "fadeInUp"
        }}
      />
      <img
        className="page-title-s-7 d-none d-md-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/page-title/page-title-s-7.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xxl-12">
          <div className="page__title-wrapper">
            <h2 className="page__title-heading">Software Development </h2>
            <nav
              aria-label="Breadcrumbs"
              className="breadcrumb-trail breadcrumbs"
            >
              <ul
                className="trail-items"
                itemScope=""
                itemType="http://schema.org/BreadcrumbList"
              >
                <li
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                  className="trail-item trail-begin"
                >
                  <a
                    href="/"
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">Home</span>
                  </a>&nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                  <meta itemProp="position" content={1} />
                </li>
                <li
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="http://schema.org/ListItem"
                  className="trail-item"
                >
                  <a
                    href="/services"
                    itemProp="item"
                  >
                    <span itemProp="name">Services</span>
                  </a>&nbsp; &nbsp; &nbsp; &nbsp;
                    <TfiAngleDoubleRight style={{color:'white',fontSize:'20px'}}/>
                 
                  <meta itemProp="position" content={2} />
                </li>
                <li className="trail-item trail-end">
                  <span>Software Development</span>
                </li>
              </ul>
            </nav>{" "}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* page-title area end */}
  <section className="services__details p-relative pt-120 pb-90">
    <div className="services__details-shape">
      <img
        className="s-details-s-1"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/s-details-s-1.png"
        alt="image"
      />
      <img
        className="s-details-s-2 d-none d-sm-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/s-details-s-2.png"
        alt="image"
      />
      <img
        className="s-details-s-3"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/services/s-details-s-3.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <div
            id="alimasha-services-cats-3"
            className="sidebar__widget sidebar__widget-2 mb-40 widget_alimasha-services-cats"
          >
            <div className="sidebar__widget-title">
              <h3>Services </h3>
            </div>
            <div className="sidebar__widget-link">
            <ul>
                <li>
                  <a href="/digital">
                    Digital Marketing{" "}
                  </a>
                </li>
                <li>
                  <a href="/consult">
                    IT Consulting Services{" "}
                  </a>
                </li>
                <li>
                  <a href="/software">
                    Software Development{" "}
                  </a>
                </li>
                <li>
                  <a href="/ux">
                    UI/UX Design{" "}
                  </a>
                </li>
                <li>
                  <a href="/mobile">
                    Mobile App Development{" "}
                  </a>
                </li>
                <li>
                  <a href="/web">
                    Web Development{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8">
          <div className="services__details-thumb mb-60">
            <img
              width={1200}
              height={722}
              src="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/blog-5.jpg"
              className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
              alt=""
              decoding="async"
              fetchpriority="high"
              srcSet="https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/blog-5.jpg 1200w, https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/blog-5-300x181.jpg 300w, https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/blog-5-1024x616.jpg 1024w, https://thememaster.net/wp/maxdi/wp-content/uploads/2022/07/blog-5-768x462.jpg 768w"
              sizes="(max-width: 1200px) 100vw, 1200px"
            />{" "}
          </div>
          <div className="services__details-content">
            <h2>Software Development</h2>
            <p>
            Anisys Technology is a leading software development company dedicated to delivering high-quality and innovative software solutions. With a team of skilled developers and engineers, Anisys Technology offers a wide range of software development services tailored to meet the unique requirements of clients from various industries            </p>
            <p>
            At Anisys Technology, we follow industry best practices and utilize cutting-edge technologies to develop software applications that are scalable, robust, and efficient.            </p>
            <p>
            
We understand that each software project is unique, and we take a comprehensive approach to ensure its success. Our software development process starts with a thorough analysis of our clients' requirements and objectives. We collaborate closely with our clients to gain a deep understanding of their business processes and tailor our solutions to align with their goals.
                </p>
                <div className="key__factors">
              <div className="key__factors-title">
                <h3>Key Factors</h3>
              </div>
              <div className="key__factors-wrapper d-flex">
                <div className="key__factors-content mb-40 d-sm-flex">
                <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/8951/8951604.png"
                                    alt="icon"
                                  style={{width:'100%',marginTop:'20px'}}
                                  />
                                </div>
                  <div className="key__factors-text">
                
                  
                    <h5>Business Automation</h5>
                    <p>
                      Energy only home we’ve ever known deciphe scended from
                      rogue.
                    </p>
                  </div>
                </div>
                <div className="key__factors-content mb-40 d-sm-flex">
                <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/11083/11083360.png"
                                    alt="icon"
                                    style={{width:'100%',marginTop:'20px'}}
                                  />
                                </div>                  <div className="key__factors-text">
                    <h5>Critical Analysis</h5>
                    <p>
                      Energy only home we’ve ever known deciphe scended from
                      rogue.
                    </p>
                  </div>
                </div>
              </div>
              <div className="key__factors-wrapper d-flex">
                <div className="key__factors-content mb-40 d-sm-flex">
                <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/473/473571.png"
                                    alt="icon"
                                    style={{width:'100%',marginTop:'20px'}}
                                  />
                                </div>                   <div className="key__factors-text">
                    <h5>Risk Protection</h5>
                    <p>
                      Energy only home we’ve ever known deciphe scended from
                      rogue.
                    </p>
                  </div>
                </div>
                <div className="key__factors-content mb-40 d-sm-flex">
                <div className="single__service-icon single__service-icon-pink">
                                  <img
                                    decoding="async"
                                    src="https://cdn-icons-png.flaticon.com/128/3029/3029319.png"
                                    alt="icon"
                                    style={{width:'100%',marginTop:'20px'}}
                                  />
                                </div>                    <div className="key__factors-text">
                    <h5>Annual Report</h5>
                    <p>
                      Energy only home we’ve ever known deciphe scended from
                      rogue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="key__benefits">
              <div className="key__benefits-title">
                <h3>Key Benefits</h3>
              </div>
              <div className="key__benefits-content">
                <ul>
                  <li>During the development phase, our experienced developers use industry-standard methodologies and frameworks to build the software solution. We emphasize clean and maintainable code, ensuring that the software is adaptable to future changes and enhancements. Our team conducts rigorous testing at each stage to identify and resolve any issues, ensuring the software's stability and reliability.</li>
                  <li>
                  At Anisys Technology, we prioritize user experience and interface design. Our skilled UX/UI designers create intuitive and visually appealing interfaces that enhance user engagement and satisfaction. We aim to create software that is user-friendly, efficient, and aligned with the latest design trends and usability principles.                  </li>
                  <li>
                  We believe in long-term partnerships with our clients, and our commitment extends beyond the development phase. Anisys Technology offers comprehensive support and maintenance services to ensure that the software remains up to date, secure, and optimized for performance. We provide timely updates, bug fixes, and feature enhancements to keep our clients' software running smoothly.                  </li>
                </ul>
              </div>
            </div>
            <p>
              
            Whether it's enterprise software, web applications, mobile apps, or custom solutions, Anisys Technology strives to deliver software that drives efficiency, productivity, and growth for our clients. With our expertise in software development and dedication to client satisfaction, we are a trusted partner for businesses seeking reliable and scalable software solutions.            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* lets-work area start */}
  <section className="lets-work__area p-relative pt-165 pb-160">
    <div className="lets-work__shape">
      <img
        className="lets-work-shape-1 d-none d-lg-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-1.png"
        alt="image"
      />
      <img
        className="lets-work-shape-2 d-none d-lg-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-2.png"
        alt="image"
      />
      <img
        className="lets-work-shape-3 d-none d-sm-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-3.png"
        alt="image"
      />
      <img
        className="lets-work-shape-4 d-none d-sm-block"
        src="https://thememaster.net/wp/maxdi/wp-content/themes/maxdi/assets/images/shape/lets-work/lets-work-s-4.png"
        alt="image"
      />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="lets-work__title text-center">
            <h4
              className="section__sub-title wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp"
              }}
            >
              Let’s Work Together{" "}
            </h4>
            <h2
              className="section__title wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp"
              }}
            >
              <span>Need a successful</span>
              project?{" "}
            </h2>
            <a
              className="m-btn mt-35 wow fadeInUp"
              data-wow-delay=".6s"
              href="https://thememaster.net/wp/maxdi/contact"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp"
              }}
            >
              Estimate Project
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* lets-work area end */}
  
</>

  )
}

export default SoftwareService