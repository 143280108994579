import React, { useState, useEffect } from 'react';

const NumberList = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count < 30) {
        setCount(count + 1);
      }
    }, 200); // Decreased delay time to 200 milliseconds

    return () => clearTimeout(timer);
  }, [count]);

  return (
    <div>
      {count <= 50 ? (
        <p style={{fontSize:'42px',color:'white',fontWeight:'bold'}}>+{count}</p>
      ) : (
        <p>Count reached 50</p>
      )}
    </div>
  );
};

export default NumberList;
